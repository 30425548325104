$(document).ready(function() {
	if(!document.querySelector('.C0051')) return false;
	
	var $obj = $('.C0051');
	$obj.each(function() {
		var $slick = $(this).find('.items-group .items').slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows : true,
			dots: true,
			responsive: [{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows : false
				}
			}],
			prevArrow: carouselOptions.bigAnglePrev, // common.js variable
			nextArrow: carouselOptions.bigAngleNext // common.js variable
		});
	});
});