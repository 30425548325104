$(document).ready(function() {
	if(!document.querySelector('.js-model-switcher')) return false;
	var modelSwicher = {
		el: document.querySelectorAll('.js-model-switcher'),
		currentEl: null,
		itemSelector: '.js-model',
		$items: null,
		ajaxUrl: null,
		paramName: null,
		bizType:'B2C',
		init: function(){
			if($('.navigation').length>0) {
				if($('.navigation').hasClass('b2b')) this.bizType = 'B2B';
			}
			console.log(this.bizType);
			for (var i = 0; i < modelSwicher.el.length; i++) {
				modelSwicher.currentEl = modelSwicher.el[i];
				modelSwicher.$items = $(modelSwicher.currentEl).find(modelSwicher.itemSelector);
				modelSwicher.ajaxUrl = modelSwicher.currentEl.getAttribute('data-model-url');
				modelSwicher.paramName = modelSwicher.currentEl.getAttribute('data-name');
				modelSwicher.addEvent();
			}
		},
		numberWithCommas: function(num) {
			if(num==0) return 0;
			num = parseFloat(num);
			if(isNaN(num)) return 0;
			var reg = /(^[+-]?\d+)(\d{3})/;
			var n = (num + '');
			while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
			return n;
		},
		addEvent: function(){
			$(modelSwicher.currentEl).on({
				click: function(e){
					e.preventDefault();
					var modelNumber = e.currentTarget.getAttribute('href'),
						groupIndex = $(e.currentTarget).index();
						methodType = $(modelSwicher.currentEl).data('ajaxMethod'),
						param = {};
					param[modelSwicher.paramName] = modelNumber;
					param["bizType"] = modelSwicher.bizType;
					ajax.call(modelSwicher.ajaxUrl, param, 'json', function(data){
						if(data && data != '') {
							var $item = $(e.currentTarget).parents(modelSwicher.itemSelector);
							if(data.status=='success') {
								var d = data.data[0];
								// change tag
								$item.find('.tag-content').empty();
								if(d.productTag1 != null) $item.find('.tag-content').append('<span>'+d.productTag1+'</span>');
								if(d.productTag2 != null) $item.find('.tag-content').append('<span>'+d.productTag2+'</span>');
								// change image and link
								$item.find('a.visual').attr('href', d.modelUrlPath);
								if(d.imageAltText != null) {
									$item.find('a.visual img').attr('alt', d.imageAltText);
								} else {
									$item.find('a.visual img').attr('alt', '');
								}
								$item.find('a.visual img.pc').attr('src', d.mediumImageAddr);
								$item.find('a.visual img.mobile').attr('src', d.smallImageAddr);
								if(d.modelRollingImgList != null && d.modelRollingImgList != '') {
									$item.find('a.visual img.pc').addClass('js-thumbnail-loop').attr('data-img-list', d.modelRollingImgList)
								} else {
									$item.find('a.visual img.pc').removeClass('js-thumbnail-loop').removeAttr('data-img-list');
								}
								// change sibling link
								$item.find('.model-group a').removeClass('active').eq(groupIndex).addClass('active');
								// change model name and link
								$item.find('.products-info .model-name a').attr('href', d.modelUrlPath).html(d.userFriendlyName);
								// change sku
								$item.find('.products-info .sku').html(d.modelName);
								// rating
								if(modelSwicher.bizType=="B2C") {
									$item.find('.rating').replaceWith('<div class="rating" data-bv-show="inline_rating" data-bv-product-id="'+d.modelId+'" data-bv-redirect-url="'+d.modelUrlPath+'#reviews"></div>');
								}
								// Price
								var currencyUnit = "$";
								var discountRateText = " Off";
								$item.find('.price-area').empty();
								if(d.promotionPrice != null && d.rPromoPrice != null && d.rPromoPriceCent != null && d.discountedRate != null) {
									// promotion price is not null
									$item.find('.price-area').append('<div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPromoPrice)+'.'+d.rPromoPriceCent+'</div></div><div class="product-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+d.rPriceCent+'</div><div class="legal">'+d.discountedRate+'%'+discountRateText+'</div></div>');
								} else {
									if(modelSwicher.bizType=="B2B") {
										$item.find('.price-area').append('<div class="msrp">msrp</div><div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+d.rPriceCent+'</div></div>');
									} else {
										$item.find('.price-area').append('<div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+d.rPriceCent+'</div></div>');
									}
								}
								// Promotion text
								if(d.promotionText != null) $item.find('.promotion-text').html(d.promotionText);
								else $item.find('.promotion-text').html('');
								// Button (add to cart)
								if(d.addToCartFlag=="Y" && d.addToCartUrl != null) $item.find('.button a.add-to-cart').addClass('active').attr('href', d.addToCartUrl);
								else $item.find('.button a.add-to-cart').removeClass('active');
								if(d.whereToBuyFlag=="Y" && d.whereToBuyUrl != null) $item.find('.button a.where-to-buy').addClass('active').attr('href', d.whereToBuyUrl);
								else $item.find('.button a.where-to-buy').removeClass('active');
								if(d.findTheDealerFlag=="Y" && d.findTheDealerUrl != null) $item.find('.button a.find-a-dealer').addClass('active').attr('href', d.findTheDealerUrl);
								else $item.find('.button a.find-a-dealer').removeClass('active');
								if(d.inquiryToBuyFlag=="Y" && d.inquiryToBuyUrl != null) $item.find('.button a.inquiry-to-buy').addClass('active').attr('href', d.inquiryToBuyUrl);
								else $item.find('.button a.inquiry-to-buy').removeClass('active');
								// compare
								$item.find('.wishlist-compare .js-compare').attr('data-product-id', d.modelId);
								if(getCookie('LG5_CompareCart') != undefined) {
									if(d.modelId, getCookie('LG5_CompareCart').indexOf(d.modelId) != -1) {
										// If compare cookie has this product
										$item.find('.wishlist-compare .js-compare').addClass('added');
									} else {
										$item.find('.wishlist-compare .js-compare').removeClass('added');
									}
								} else {
									$item.find('.wishlist-compare .js-compare').removeClass('added');
								}
							}
						}
					}, methodType);
				}
			}, modelSwicher.itemSelector+' .model-group a');
		}
	};
	modelSwicher.init();
});