$(document).ready(function() {
	if(!document.querySelector('.see-video')) return false;
	var video = {
		cache: {
			$modal: null,
			id: null
		},
		markup: {
			modal: [
				'<div class="video-modal video-box-closeset youtube"><div class="modal-video-asset"><div class="video-asset">',
				'<div class="video-modal video-box-closeset brightcove"><div class="modal-video-asset"><div class="video-asset">',
				'</div></div><button class="close-video">Close Video</button></div>'
			],
			// iframe: null
			current: [
				'<div class="video-asset video-box-closeset">',
				'<button class="close-video">Close Video</button></div>'
			]
		},
		getTitle: function(t) {
			// find video title 
			// 1. data-title
			// 2. a > .caption text
			// 3. a.s .head text
			// 4. a.s .txt-box text
			// 5. a's parents .visual-area > .caption text
			// 6. a's parents .item > .caption text
			// 7. a's parents .slick-slide > .caption text
			// 8. for 57 components
			// 9. a's parents .unit-box > .title text
			// 10. a's parents .component > .title text
			var videoTitle = '';
			if(videoTitle==null || videoTitle=='') { // 1
				videoTitle = t.getAttribute('data-title');
				//console.log('videoTitle', 1, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 2
				videoTitle = $(t).find('.caption').text();
				//console.log('videoTitle', 2, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 3
				videoTitle = $(t).find('.head').text();
				//console.log('videoTitle', 3, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 4
				videoTitle = $(t).find('.txt-box').text();
				//console.log('videoTitle', 4, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 5
				videoTitle = $(t).closest('.visual-area').find('.caption').text();
				//console.log('videoTitle', 5, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 6
				videoTitle = $(t).closest('.item').find('.caption').text();
				//console.log('videoTitle', 6, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 7
				videoTitle = $(t).closest('.slick-slide').find('.caption').text();
				//console.log('videoTitle', 7, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 8 (for 57 components)
				videoTitle = $(t).closest('.modal-body').find('.text-area .text-box').eq($(t).closest('.slick-slide').index()).find('.text-box-head').text();
				//console.log('videoTitle', 8, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 9
				videoTitle = $(t).closest('.unit-box').find('.title').text();
				//console.log('videoTitle', 9, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') { // 10
				videoTitle = $(t).closest('.component').find('.title').text();
				//console.log('videoTitle', 10, videoTitle);
			}
			if(videoTitle==null || videoTitle=='') {
				videoTitle='';
			}
			return $.trim(videoTitle);
		},
		trigger: {
			init: function(){
				var tr = video.trigger;
				tr.addEvent();
			},
			setYoutube: function(t){
				var _this = t;
				var videoId = t.getAttribute('data-src');
				var videoTitle = video.getTitle(t);
				var iframe = null,
					html = null;
				if(videoId.indexOf('/') > 0) {
					var i = videoId.split('/');
					videoId = i[i.length-1];
				}
				var playsinline = (t.getAttribute('data-target') != 'modal') ? '&playsinline=1' : '';
				iframe = '<iframe src="https://www.youtube.com/embed/'+videoId+'?&autoplay=1'+playsinline+'" allow="autoplay; encrypted-media"  title="'+ videoTitle +'" allowfullscreen></iframe>';
				if(t.getAttribute('data-target') == 'modal') {
					// if(video.cache.id != videoId) {
						html = video.markup.modal[1]+ iframe +video.markup.modal[2];
					// }
					video.appendModal(html, videoId);
				}else {
					html = video.markup.current[0]+ iframe +video.markup.current[1];
					video.appendCurrent(html, t);
				}
			},
			setBrightcove: function(t){
				var videoId = t.getAttribute('data-video-id');
				var videoAccount = t.getAttribute('data-account');
				var videoPlayer = t.getAttribute('data-player');
				var videoTitle = video.getTitle(t);
				var iframe = null,
					html = null;
				
				iframe = '<iframe src="https://players.brightcove.net/'+ videoAccount +'/'+ videoPlayer +'_default/index.html?videoId='+ videoId +'&autoplay=play" title="'+ videoTitle +'"  allowfullscreen="true" autoplay="play" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>';

				if(t.getAttribute('data-target') == 'modal') {
					// if(video.cache.id != videoId) {
						html = video.markup.modal[1]+ iframe +video.markup.modal[2];
					// }
					video.appendModal(html, videoId);
				}else {
					html = video.markup.current[0]+ iframe +video.markup.current[1];
					video.appendCurrent(html, t);
				}
				
			},
			addEvent: function(){
				var tr = video.trigger;
				var $component = $('.component');
				if($component.length <= 0) {
					$component = $('.container-fluid');
				}
				$component.off().on({
					click: function(e){
						e.preventDefault();
						var _this = e.currentTarget;
						var unCache = true;
						if(_this.getAttribute('data-target') != 'modal') {
							// is component player
							var $cv = $(_this).data('cachedvideo');
							if($cv) {
								video.appendCurrent($cv, _this, true);
								unCache = false;
							}else {
								// console.log('nocache');
								unCache = true;
							}
						}

						if(unCache && (_this.getAttribute('data-type') == 'youtube')) {
							tr.setYoutube(_this);
						}else if(unCache && (_this.getAttribute('data-type') == 'brightcove')) {
							tr.setBrightcove(_this);
						}
						
						// stop autoplay by clicking the video button
						var C0055 = $(_this).closest('.component.C0055').find('.type-hero');
						if(C0055.length>0) {
							C0055.next().find('a.slide-pause.pause').trigger('click');
						}
					}
				}, '.see-video');
			}
		},
		appendCurrent: function(mk, anchor, cached){
			var html = mk;
			var $target = $(anchor).parents('.video-box');
			$target = ($target.get(0)) ? $target : $(anchor).parents('.video-box').find('.visual-area');
			var $asset = $target.find('.video-asset');
			var $invisible = $(anchor).parents('.carousel-box').find('.text-area');

			if($asset) $asset.remove();


			$invisible.animate({
				opacity: 0
			}, 500);
			$target.parents('.component').addClass('js-invisible-nav');

			// $invisible.animate({
			// 	opacity: 0
			// }, 500);
			setTimeout(function(){
				$invisible.css({
					zIndex: -1
				});
				$target.append(html);
				if(!cached) {
					// init
					video.initCompo(anchor); // saved cache data in anchor
				}
			}, 500);
			
			
		},
		appendModal: function(mk, id){
			var modal = document.querySelectorAll('.video-modal')[0];
			var html = mk;

			if(modal) $(modal).remove();

			// if(video.cache.id == id){ // is cached video
			// 	html = video.cache.$modal;
			// 	$('body').append(html);
			// }else { // is not cached video
				
			// }

			$('body').addClass('modal-open').append(html);
			video.initModal();
			video.cache.id = id;
		},
		initCompo: function(cache){
			$('.video-asset').on({
				click: function(e){
					e.preventDefault();
					var $current = $(e.delegateTarget);
					var $invisible = $(e.delegateTarget).parents('.video-box').find('.text-area');

					$current.parents('.component').removeClass('js-invisible-nav');
					$(cache).data('cachedvideo', $current.detach());

					// $invisible.removeAttr('style').animate({
					// 	opacity: 1
					// }, 500);
					$invisible.removeAttr('style');
				}
			}, '.close-video');
		},
		initModal: function(){
			$('.video-modal').on({
				click: function(e){
					e.preventDefault();
					var $currentModal = $(e.delegateTarget);
					video.cache.$modal = $currentModal.detach();
					$('body').removeClass('modal-open');
				}
			}, '.close-video');
		},
		init: function(){
			video.trigger.init();
			// video.addEvent();
		}
	};

	video.init();
});
