$(document).ready(function() {
	if(!document.querySelector('.C0058')) return false;

	var $obj = $('.C0058');
	var $slickInit = function(){
		var $slick = $obj.find('.item-group').slick({
			infinite:true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows : false,
			dots: true
		});
	};
	var $unSlick = function(){
		if ($obj.find('.item-group').hasClass('slick-initialized')){
			var $slick = $obj.find('.item-group').slick('unslick');
			$obj.find('.item').removeAttr('tabindex');
			$obj.find('.item a').removeAttr('tabindex');
		} else {
			return false;
		}
	};

	var screenSlick = function(e) {
		if (e.matches) {
			$slickInit();
		} else {
			$unSlick();
		}
	};

	mql.maxSm.addListener(screenSlick);
	screenSlick(mql.maxSm);
});