/* jshint ignore:start */
$(document).ready(function(){
	if(!document.querySelector('.C0020')) return false;

	var isGoogleMap = document.getElementById('findaDealer') || null;

	window.initialize = function() {

		// infoBox
			function InfoBox(opt_opts) {
				opt_opts = opt_opts || {};
				google.maps.OverlayView.apply(this, arguments);
				// Standard options (in common with google.maps.InfoWindow):
				//
				this.content_ = opt_opts.content || "";
				this.disableAutoPan_ = opt_opts.disableAutoPan || false;
				this.maxWidth_ = opt_opts.maxWidth || 0;
				this.pixelOffset_ = opt_opts.pixelOffset || new google.maps.Size(0, 0);
				this.position_ = opt_opts.position || new google.maps.LatLng(0, 0);
				this.zIndex_ = opt_opts.zIndex || null;

				// Additional options (unique to InfoBox):
				//
				this.boxClass_ = opt_opts.boxClass || "infoBox";
				this.boxStyle_ = opt_opts.boxStyle || {};
				this.closeBoxMargin_ = opt_opts.closeBoxMargin || "2px";
				this.closeBoxURL_ = opt_opts.closeBoxURL || "http://www.google.com/intl/en_us/mapfiles/close.gif";
				if (opt_opts.closeBoxURL === "") {
					this.closeBoxURL_ = "";
				}
				this.infoBoxClearance_ = opt_opts.infoBoxClearance || new google.maps.Size(1, 1);

				if (typeof opt_opts.visible === "undefined") {
					if (typeof opt_opts.isHidden === "undefined") {
						opt_opts.visible = true;
					} else {
						opt_opts.visible = !opt_opts.isHidden;
					}
				}
				this.isHidden_ = !opt_opts.visible;

				this.alignBottom_ = opt_opts.alignBottom || false;
				this.pane_ = opt_opts.pane || "floatPane";
				this.enableEventPropagation_ = opt_opts.enableEventPropagation || false;

				this.div_ = null;
				this.closeListener_ = null;
				this.moveListener_ = null;
				this.contextListener_ = null;
				this.eventListeners_ = null;
				this.fixedWidthSet_ = null;
			}

			/* InfoBox extends OverlayView in the Google Maps API v3.
			 */
			InfoBox.prototype = new google.maps.OverlayView();

			/**
			 * Creates the DIV representing the InfoBox.
			 * @private
			 */
			InfoBox.prototype.createInfoBoxDiv_ = function() {

				var i;
				var events;
				var bw;
				var me = this;

				// This handler prevents an event in the InfoBox from being passed on to the map.
				//
				var cancelHandler = function(e) {
					e.cancelBubble = true;
					if (e.stopPropagation) {
						e.stopPropagation();
					}
				};

				// This handler ignores the current event in the InfoBox and conditionally prevents
				// the event from being passed on to the map. It is used for the contextmenu event.
				//
				var ignoreHandler = function(e) {

					e.returnValue = false;

					if (e.preventDefault) {

						e.preventDefault();
					}

					if (!me.enableEventPropagation_) {

						cancelHandler(e);
					}
				};

				if (!this.div_) {

					this.div_ = document.createElement("div");

					this.setBoxStyle_();

					if (typeof this.content_.nodeType === "undefined") {
						this.div_.innerHTML = this.getCloseBoxImg_() + this.content_;
					} else {
						this.div_.innerHTML = this.getCloseBoxImg_();
						this.div_.appendChild(this.content_);
					}

					// Add the InfoBox DIV to the DOM
					this.getPanes()[this.pane_].appendChild(this.div_);

					this.addClickHandler_();

					if (this.div_.style.width) {

						this.fixedWidthSet_ = true;

					} else {

						if (this.maxWidth_ !== 0 && this.div_.offsetWidth > this.maxWidth_) {

							this.div_.style.width = this.maxWidth_;
							this.div_.style.overflow = "auto";
							this.fixedWidthSet_ = true;

						} else { // The following code is needed to overcome problems with MSIE

							bw = this.getBoxWidths_();

							this.div_.style.width = (this.div_.offsetWidth - bw.left - bw.right) + "px";
							this.fixedWidthSet_ = false;
						}
					}

					this.panBox_(this.disableAutoPan_);

					if (!this.enableEventPropagation_) {

						this.eventListeners_ = [];

						// Cancel event propagation.
						//
						// Note: mousemove not included (to resolve Issue 152)
						events = ["mousedown", "mouseover", "mouseout", "mouseup",
							"click", "dblclick", "touchstart", "touchend", "touchmove"
						];

						for (i = 0; i < events.length; i++) {

							this.eventListeners_.push(google.maps.event.addDomListener(this.div_, events[i], cancelHandler));
						}

						// Workaround for Google bug that causes the cursor to change to a pointer
						// when the mouse moves over a marker underneath InfoBox.
						this.eventListeners_.push(google.maps.event.addDomListener(this.div_, "mouseover", function(e) {
							this.style.cursor = "default";
						}));
					}

					this.contextListener_ = google.maps.event.addDomListener(this.div_, "contextmenu", ignoreHandler);

					/**
					 * This event is fired when the DIV containing the InfoBox's content is attached to the DOM.
					 * @name InfoBox#domready
					 * @event
					 */
					google.maps.event.trigger(this, "domready");
				}
			};

			/**
			 * Returns the HTML <IMG> tag for the close box.
			 * @private
			 */
			InfoBox.prototype.getCloseBoxImg_ = function() {

				var img = "";

				if (this.closeBoxURL_ !== "") {

					img = "<a class='window-close' id='infoWindowClose'><div class='sr-only'>close</div></a>";
				}

				return img;
			};

			/**
			 * Adds the click handler to the InfoBox close box.
			 * @private
			 */
			InfoBox.prototype.addClickHandler_ = function() {

				var closeBox;

				if (this.closeBoxURL_ !== "") {

					closeBox = this.div_.firstChild;
					this.closeListener_ = google.maps.event.addDomListener(closeBox, "click", this.getCloseClickHandler_());

				} else {

					this.closeListener_ = null;
				}
			};

			/**
			 * Returns the function to call when the user clicks the close box of an InfoBox.
			 * @private
			 */
			InfoBox.prototype.getCloseClickHandler_ = function() {

				var me = this;

				return function(e) {

					// 1.0.3 fix: Always prevent propagation of a close box click to the map:
					e.cancelBubble = true;

					if (e.stopPropagation) {

						e.stopPropagation();
					}

					/**
					 * This event is fired when the InfoBox's close box is clicked.
					 * @name InfoBox#closeclick
					 * @event
					 */
					google.maps.event.trigger(me, "closeclick");

					me.close();
				};
			};

			/**
			 * Pans the map so that the InfoBox appears entirely within the map's visible area.
			 * @private
			 */
			InfoBox.prototype.panBox_ = function(disablePan) {

				var map;
				var bounds;
				var xOffset = 0,
					yOffset = 0;

				if (!disablePan) {

					map = this.getMap();

					if (map instanceof google.maps.Map) { // Only pan if attached to map, not panorama

						if (!map.getBounds().contains(this.position_)) {
							// Marker not in visible area of map, so set center
							// of map to the marker position first.
							map.setCenter(this.position_);
						}

						bounds = map.getBounds();

						var mapDiv = map.getDiv();
						var mapWidth = mapDiv.offsetWidth;
						var mapHeight = mapDiv.offsetHeight;
						var iwOffsetX = this.pixelOffset_.width;
						var iwOffsetY = this.pixelOffset_.height;
						var iwWidth = this.div_.offsetWidth;
						var iwHeight = this.div_.offsetHeight;
						var padX = this.infoBoxClearance_.width;
						var padY = this.infoBoxClearance_.height;
						var pixPosition = this.getProjection().fromLatLngToContainerPixel(this.position_);

						if (pixPosition.x < (-iwOffsetX + padX)) {
							xOffset = pixPosition.x + iwOffsetX - padX;
						} else if ((pixPosition.x + iwWidth + iwOffsetX + padX) > mapWidth) {
							xOffset = pixPosition.x + iwWidth + iwOffsetX + padX - mapWidth;
						}
						if (this.alignBottom_) {
							if (pixPosition.y < (-iwOffsetY + padY + iwHeight)) {
								yOffset = pixPosition.y + iwOffsetY - padY - iwHeight;
							} else if ((pixPosition.y + iwOffsetY + padY) > mapHeight) {
								yOffset = pixPosition.y + iwOffsetY + padY - mapHeight;
							}
						} else {
							if (pixPosition.y < (-iwOffsetY + padY)) {
								yOffset = pixPosition.y + iwOffsetY - padY;
							} else if ((pixPosition.y + iwHeight + iwOffsetY + padY) > mapHeight) {
								yOffset = pixPosition.y + iwHeight + iwOffsetY + padY - mapHeight;
							}
						}

						if (!(xOffset === 0 && yOffset === 0)) {

							// Move the map to the shifted center.
							//
							var c = map.getCenter();
							map.panBy(xOffset, yOffset);
						}
					}
				}
			};

			/**
			 * Sets the style of the InfoBox by setting the style sheet and applying
			 * other specific styles requested.
			 * @private
			 */
			InfoBox.prototype.setBoxStyle_ = function() {

				var i, boxStyle;

				if (this.div_) {

					// Apply style values from the style sheet defined in the boxClass parameter:
					this.div_.className = this.boxClass_;

					// Clear existing inline style values:
					this.div_.style.cssText = "";

					// Apply style values defined in the boxStyle parameter:
					boxStyle = this.boxStyle_;
					for (i in boxStyle) {

						if (boxStyle.hasOwnProperty(i)) {

							this.div_.style[i] = boxStyle[i];
						}
					}

					// Fix for iOS disappearing InfoBox problem.
					// See http://stackoverflow.com/questions/9229535/google-maps-markers-disappear-at-certain-zoom-level-only-on-iphone-ipad
					this.div_.style.WebkitTransform = "translateZ(0)";

					// Fix up opacity style for benefit of MSIE:
					//
					if (typeof this.div_.style.opacity !== "undefined" && this.div_.style.opacity !== "") {
						// See http://www.quirksmode.org/css/opacity.html
						this.div_.style.MsFilter = "\"progid:DXImageTransform.Microsoft.Alpha(Opacity=" + (this.div_.style.opacity * 100) + ")\"";
						this.div_.style.filter = "alpha(opacity=" + (this.div_.style.opacity * 100) + ")";
					}

					// Apply required styles:
					//
					this.div_.style.position = "absolute";
					this.div_.style.visibility = 'hidden';
					if (this.zIndex_ !== null) {

						this.div_.style.zIndex = this.zIndex_;
					}
				}
			};

			/**
			 * Get the widths of the borders of the InfoBox.
			 * @private
			 * @return {Object} widths object (top, bottom left, right)
			 */
			InfoBox.prototype.getBoxWidths_ = function() {

				var computedStyle;
				var bw = {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0
				};
				var box = this.div_;

				if (document.defaultView && document.defaultView.getComputedStyle) {

					computedStyle = box.ownerDocument.defaultView.getComputedStyle(box, "");

					if (computedStyle) {

						// The computed styles are always in pixel units (good!)
						bw.top = parseInt(computedStyle.borderTopWidth, 10) || 0;
						bw.bottom = parseInt(computedStyle.borderBottomWidth, 10) || 0;
						bw.left = parseInt(computedStyle.borderLeftWidth, 10) || 0;
						bw.right = parseInt(computedStyle.borderRightWidth, 10) || 0;
					}

				} else if (document.documentElement.currentStyle) { // MSIE

					if (box.currentStyle) {

						// The current styles may not be in pixel units, but assume they are (bad!)
						bw.top = parseInt(box.currentStyle.borderTopWidth, 10) || 0;
						bw.bottom = parseInt(box.currentStyle.borderBottomWidth, 10) || 0;
						bw.left = parseInt(box.currentStyle.borderLeftWidth, 10) || 0;
						bw.right = parseInt(box.currentStyle.borderRightWidth, 10) || 0;
					}
				}

				return bw;
			};

			/**
			 * Invoked when <tt>close</tt> is called. Do not call it directly.
			 */
			InfoBox.prototype.onRemove = function() {

				if (this.div_) {

					this.div_.parentNode.removeChild(this.div_);
					this.div_ = null;
				}
			};

			/**
			 * Draws the InfoBox based on the current map projection and zoom level.
			 */
			InfoBox.prototype.draw = function() {

				this.createInfoBoxDiv_();

				var pixPosition = this.getProjection().fromLatLngToDivPixel(this.position_);

				this.div_.style.left = (pixPosition.x + this.pixelOffset_.width) + "px";

				if (this.alignBottom_) {
					this.div_.style.bottom = -(pixPosition.y + this.pixelOffset_.height) + "px";
				} else {
					this.div_.style.top = (pixPosition.y + this.pixelOffset_.height) + "px";
				}

				if (this.isHidden_) {

					this.div_.style.visibility = "hidden";

				} else {

					this.div_.style.visibility = "visible";
				}
			};

			/**
			 * Sets the options for the InfoBox. Note that changes to the <tt>maxWidth</tt>,
			 *  <tt>closeBoxMargin</tt>, <tt>closeBoxURL</tt>, and <tt>enableEventPropagation</tt>
			 *  properties have no affect until the current InfoBox is <tt>close</tt>d and a new one
			 *  is <tt>open</tt>ed.
			 * @param {InfoBoxOptions} opt_opts
			 */
			InfoBox.prototype.setOptions = function(opt_opts) {
				if (typeof opt_opts.boxClass !== "undefined") { // Must be first

					this.boxClass_ = opt_opts.boxClass;
					this.setBoxStyle_();
				}
				if (typeof opt_opts.boxStyle !== "undefined") { // Must be second

					this.boxStyle_ = opt_opts.boxStyle;
					this.setBoxStyle_();
				}
				if (typeof opt_opts.content !== "undefined") {

					this.setContent(opt_opts.content);
				}
				if (typeof opt_opts.disableAutoPan !== "undefined") {

					this.disableAutoPan_ = opt_opts.disableAutoPan;
				}
				if (typeof opt_opts.maxWidth !== "undefined") {

					this.maxWidth_ = opt_opts.maxWidth;
				}
				if (typeof opt_opts.pixelOffset !== "undefined") {

					this.pixelOffset_ = opt_opts.pixelOffset;
				}
				if (typeof opt_opts.alignBottom !== "undefined") {

					this.alignBottom_ = opt_opts.alignBottom;
				}
				if (typeof opt_opts.position !== "undefined") {

					this.setPosition(opt_opts.position);
				}
				if (typeof opt_opts.zIndex !== "undefined") {

					this.setZIndex(opt_opts.zIndex);
				}
				if (typeof opt_opts.closeBoxMargin !== "undefined") {

					this.closeBoxMargin_ = opt_opts.closeBoxMargin;
				}
				if (typeof opt_opts.closeBoxURL !== "undefined") {

					this.closeBoxURL_ = opt_opts.closeBoxURL;
				}
				if (typeof opt_opts.infoBoxClearance !== "undefined") {

					this.infoBoxClearance_ = opt_opts.infoBoxClearance;
				}
				if (typeof opt_opts.isHidden !== "undefined") {

					this.isHidden_ = opt_opts.isHidden;
				}
				if (typeof opt_opts.visible !== "undefined") {

					this.isHidden_ = !opt_opts.visible;
				}
				if (typeof opt_opts.enableEventPropagation !== "undefined") {

					this.enableEventPropagation_ = opt_opts.enableEventPropagation;
				}

				if (this.div_) {

					this.draw();
				}
			};

			/**
			 * Sets the content of the InfoBox.
			 *  The content can be plain text or an HTML DOM node.
			 * @param {string|Node} content
			 */
			InfoBox.prototype.setContent = function(content) {
				this.content_ = content;

				if (this.div_) {

					if (this.closeListener_) {

						google.maps.event.removeListener(this.closeListener_);
						this.closeListener_ = null;
					}

					// Odd code required to make things work with MSIE.
					//
					if (!this.fixedWidthSet_) {

						this.div_.style.width = "";
					}

					if (typeof content.nodeType === "undefined") {
						this.div_.innerHTML = this.getCloseBoxImg_() + content;
					} else {
						this.div_.innerHTML = this.getCloseBoxImg_();
						this.div_.appendChild(content);
					}

					// Perverse code required to make things work with MSIE.
					// (Ensures the close box does, in fact, float to the right.)
					//
					if (!this.fixedWidthSet_) {
						this.div_.style.width = this.div_.offsetWidth + "px";
						if (typeof content.nodeType === "undefined") {
							this.div_.innerHTML = this.getCloseBoxImg_() + content;
						} else {
							this.div_.innerHTML = this.getCloseBoxImg_();
							this.div_.appendChild(content);
						}
					}

					this.addClickHandler_();
				}

				/**
				 * This event is fired when the content of the InfoBox changes.
				 * @name InfoBox#content_changed
				 * @event
				 */
				google.maps.event.trigger(this, "content_changed");
			};

			/**
			 * Sets the geographic location of the InfoBox.
			 * @param {LatLng} latlng
			 */
			InfoBox.prototype.setPosition = function(latlng) {

				this.position_ = latlng;

				if (this.div_) {

					this.draw();
				}

				/**
				 * This event is fired when the position of the InfoBox changes.
				 * @name InfoBox#position_changed
				 * @event
				 */
				google.maps.event.trigger(this, "position_changed");
			};

			/**
			 * Sets the zIndex style for the InfoBox.
			 * @param {number} index
			 */
			InfoBox.prototype.setZIndex = function(index) {

				this.zIndex_ = index;

				if (this.div_) {

					this.div_.style.zIndex = index;
				}

				/**
				 * This event is fired when the zIndex of the InfoBox changes.
				 * @name InfoBox#zindex_changed
				 * @event
				 */
				google.maps.event.trigger(this, "zindex_changed");
			};

			/**
			 * Sets the visibility of the InfoBox.
			 * @param {boolean} isVisible
			 */
			InfoBox.prototype.setVisible = function(isVisible) {

				this.isHidden_ = !isVisible;
				if (this.div_) {
					this.div_.style.visibility = (this.isHidden_ ? "hidden" : "visible");
				}
			};

			/**
			 * Returns the content of the InfoBox.
			 * @returns {string}
			 */
			InfoBox.prototype.getContent = function() {

				return this.content_;
			};

			/**
			 * Returns the geographic location of the InfoBox.
			 * @returns {LatLng}
			 */
			InfoBox.prototype.getPosition = function() {
				return this.position_;
			};

			/**
			 * Returns the zIndex for the InfoBox.
			 * @returns {number}
			 */
			InfoBox.prototype.getZIndex = function() {

				return this.zIndex_;
			};

			/**
			 * Returns a flag indicating whether the InfoBox is visible.
			 * @returns {boolean}
			 */
			InfoBox.prototype.getVisible = function() {

				var isVisible;

				if ((typeof this.getMap() === "undefined") || (this.getMap() === null)) {
					isVisible = false;
				} else {
					isVisible = !this.isHidden_;
				}
				return isVisible;
			};

			/**
			 * Shows the InfoBox. [Deprecated; use <tt>setVisible</tt> instead.]
			 */
			InfoBox.prototype.show = function() {

				this.isHidden_ = false;
				if (this.div_) {
					this.div_.style.visibility = "visible";
				}
			};

			/**
			 * Hides the InfoBox. [Deprecated; use <tt>setVisible</tt> instead.]
			 */
			InfoBox.prototype.hide = function() {

				this.isHidden_ = true;
				if (this.div_) {
					this.div_.style.visibility = "hidden";
				}
			};

			/**
			 * Adds the InfoBox to the specified map or Street View panorama. If <tt>anchor</tt>
			 *  (usually a <tt>google.maps.Marker</tt>) is specified, the position
			 *  of the InfoBox is set to the position of the <tt>anchor</tt>. If the
			 *  anchor is dragged to a new location, the InfoBox moves as well.
			 * @param {Map|StreetViewPanorama} map
			 * @param {MVCObject} [anchor]
			 */
			InfoBox.prototype.open = function(map, anchor) {

				var me = this;

				if (anchor) {
					this.position_ = anchor.getPosition();
					this.moveListener_ = google.maps.event.addListener(anchor, "position_changed", function() {
						me.setPosition(this.getPosition());
					});
				}

				this.setMap(map);

				if (this.div_) {

					this.panBox_();
				}
			};

			/**
			 * Removes the InfoBox from the map.
			 */
			InfoBox.prototype.close = function() {

				var i;

				if (this.closeListener_) {

					google.maps.event.removeListener(this.closeListener_);
					this.closeListener_ = null;
				}

				if (this.eventListeners_) {

					for (i = 0; i < this.eventListeners_.length; i++) {

						google.maps.event.removeListener(this.eventListeners_[i]);
					}
					this.eventListeners_ = null;
				}

				if (this.moveListener_) {

					google.maps.event.removeListener(this.moveListener_);
					this.moveListener_ = null;
				}

				if (this.contextListener_) {

					google.maps.event.removeListener(this.contextListener_);
					this.contextListener_ = null;
				}

				this.setMap(null);
			};

			//return InfoBox;

		// theJ
		var GoogleMaps = {
			options: {
				country: $('#findaDealer').data('country'),
				zoomLevel: 5,
				zoomLevelMin: 2,
				zoomLevelMax: 24,
				viewport: null,
				countryRestriction: true,
				queryZoomLevel: $('#findaDealer').data('query-zoom-level'),
				queryUrl: $('#findaDealer').data('query-url'),
				queryType: "json",
				unitMetric: false,
				useDirection: $('#repairCenter').data('use-direction'),
				centerLatitude: $('#repairCenter').data('center-latitude'),
				centerLongitude: $('#repairCenter').data('center-longitude'),
				useController: false,
				debug: false
			},
			_init : function() {
				this.$findaDealer = $(".C0020");
				this.$repairSection = this.$findaDealer.find('.find-a-dealer-information');
				this.$repairTitle = this.$findaDealer.find('.find-a-dealer-information a.btn-collapse');
				this.$repairStoreList = this.$findaDealer.find("#repairCenterResult");
				this.$searchInput = this.$findaDealer.find('#mapSearch');
				this.$productSelect = this.$findaDealer.find('#repairProduct');
				this.$searchBtn = this.$findaDealer.find('#findaDealerSearch');
				this.$repairForm = this.$findaDealer.find('#repairForm');
				this.$repairMapArea = this.$findaDealer.find('#repairMap');
				// 없는 것 다시 넣기
				this.$resultLocation = this.$findaDealer.find('.search-location');
				this.$directionsForm = this.$findaDealer.find('#directionForm');
				this.$expandaleMap = this.$findaDealer.find('.map-expend');

				var self = this;
					self.geoResult = {};
					self.markers = [];
					self.activeItem = null;
					self.searchHandle = false;
					self.distance = 25;
					self.afterControl = false;
					self.openDirection = self.options.useDirection;

				self.timing = {
					queryTimeout: null
				};

				self.markerIcon = {
					size: new google.maps.Size(30, 43),
					anchor: new google.maps.Point(19, 54)
				};

				self.mapSetting();
				this.$repairSection.removeClass('active');
			},
			mapSetting : function() {
				var self = this;
				self.mapDiv = document.getElementById("repairMap");
				self.keyword = document.getElementById("mapSearch");
				self.locaitonKey = document.getElementById("repairOrigin");
				self.mapOptions = {
					zoom: self.options.zoomLevel,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					mapTypeControl: self.options.useController,
					mapTypeControlOptions: {
						position: google.maps.ControlPosition.LEFT_TOP
					},
					panControl: self.options.useController,
					panControlOptions: {
						position: google.maps.ControlPosition.RIGHT_TOP
					},
					//zoomControl: self.options.useController,
					zoomControl: true,

					zoomControlOptions: {
						position: google.maps.ControlPosition.RIGHT_BOTTOM
					},
					scaleControl: self.options.useController,
					scaleControlOptions: {
						position: google.maps.ControlPosition.RIGHT_TOP
					},
					//streetViewControl: self.options.useController,
					streetViewControl: true,
					streetViewControlOptions: {
						position: google.maps.ControlPosition.RIGHT_BOTTOM
					},
					minZoom: self.options.zoomLevelMin,
					maxZoom: self.options.zoomLevelMax
				};
				self.infoOptions = {
					disableAutoPan: true,
					alignBottom: true,
					pixelOffset: new google.maps.Size(-162, -54),
					zIndex: 99,
					boxStyle: {
						width: "320px"
					},
					infoBoxClearance: new google.maps.Size(1, 1),
					enableEventPropagation: true,
					boxClass: "info-window"
				};
				self.map = new google.maps.Map(self.mapDiv, self.mapOptions);
				self.infoBox = new InfoBox(self.infoOptions);
				self.directionService = new google.maps.DirectionsService();
				self.directionsDisplay = new google.maps.DirectionsRenderer();
				self.mapControl();
				self.center = self.map.getCenter();
				if (!self.openDirection) {
					self.setCountry(self.options.country);
					self.autoZipcode(self.keyword);
				} else {
					var pos = new google.maps.LatLng(self.options.centerLatitude, self.options.centerLongitude);
					self.setCountry(pos);
					self.map.setZoom(13);
					self.addMarkers(pos);
					self.autoZipcode(self.locaitonKey);
					$.proxy(self.setDirectionPanel, self);
				}
			},
			//autocomplet input
			autoZipcode : function(element) {
				var self = this;
				self.autoCompleteOption = {
					componentRestrictions: {
						country: self.options.country
					}
				};
				self.autoComplete = new google.maps.places.Autocomplete(element, self.autoCompleteOption);
				google.maps.event.addListener(self.autoComplete, 'place_changed', $.proxy(function(e) {
					var place = self.autoComplete.getPlace();
					// self.$searchBtn.click();
				}, self));
			},
			mapControl : function() {
				var self = this;
				//drag event
				google.maps.event.addListener(self.map, 'dragend', $.proxy(function(e) {
					self.center = self.map.getCenter();
					if (!self.openDirection) {
						if (self.afterControl) {
							self.getStore();
							self.setRestrictionByGeocode(self.center);
						}
					}
					google.maps.event.trigger(self.map, "resize");
				}, self));
				//gps event
				self.$findaDealer.delegate("a.repair-gps", "click", $.proxy(function(e) {
					var self = this;
					e.preventDefault();
					if (!self.openDirection) {
						navigator.geolocation.getCurrentPosition(function(position) {
							var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
							self.gpsCenter = pos;

							self.geocoder.geocode({
								'latLng': pos
							}, $.proxy(function(results, status) {
								if (status == google.maps.GeocoderStatus.OK) {
									self.directionsAddress = results[0].formatted_address;
									document.getElementById("serviceCenterZip").value = self.directionsAddress;
								}
							}, this));
						});
					} else {
						navigator.geolocation.getCurrentPosition(function(position) {
							var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

							self.geocoder.geocode({
								'latLng': pos
							}, $.proxy(function(results, status) {
								if (status == google.maps.GeocoderStatus.OK) {
									self.directionsAddress = results[0].formatted_address;
									document.getElementById("repairOrigin").value = self.directionsAddress;
								}
							}, this));
						});
					}
				}, self));
				//layer toggle event

				self.$repairTitle.on('click', function(e) {
					e.preventDefault();
					var list = GoogleMaps.$repairSection;
					if(list.hasClass('active')) {
						list.removeClass('active');
					} else {
						list.addClass('active');
					}
				});
				self.$expandaleMap.on('click', $.proxy(self.mapExpand, self));
				self.$repairForm.on('submit', $.proxy(self.searchAddress, self));
			},
			queryData : function() {
				var self = this;
				self.dataque = self.$repairForm.serialize() + "&" + $.param({
					lat: self.map.getCenter().lat(),
					lng: self.map.getCenter().lng(),
					zipCode: self.options.country,
					zoomLevel:self.options.queryZoomLevel
				});
				self.center = self.map.getCenter();
				self.clearMarker();
				$.ajax({
					url: self.options.queryUrl,
					data: xssfilter(self.dataque),
					dataType: self.options.queryType,
					error: function(xhr) {
						self.reset();
						self.setErrorList();
						//console.log(xhr);
					},
					success: function(data) {
						self.map.process = true;
						self.markers = [];
						self.stores = [];

						if (data.length) {
							var curBounds = new google.maps.LatLngBounds();
							var insetMarker = 0;

							//list reset
							self.reset();
							self.$repairStoreList.parent().find('.no-items').removeClass('active');

							$.each(data, function(item) {
								var latlng = new google.maps.LatLng(data[item].dealerLatitudeValue, data[item].dealerLongitudeValue);
								self.markerIcon.url = "/lg5-common/images/support/find-service-center/mark-" + (parseInt(item, 10) + 101).toString().substr(1) + ".png";
								var curMarker = $.extend(true, {}, self.markerIcon);
								self.addMarkers(latlng, curMarker, item);
								self.stores.push(data[item]);
								self.setRepairList(data, item);
								curBounds.extend(latlng);
								insetMarker += self.map.getBounds().contains(latlng) ? 1 : 0;
							});

							// bind event
							self.$repairStoreList.find('.list .dealer-name a').off('click').on('click', function(e) {
								//console.log('test');
								e.preventDefault();
								self.infoBox.close();
								var item = $(this).data('item');
								self.activeItem = item;
								if ($(window).width()>=768) {
									self.offsetCenter(self.markers[item].getPosition(), 220, -100, self.map);
								} else {
									self.offsetCenter(self.markers[item].getPosition(), 0, -100, self.map);
								}
								self.setStoreInfo(self.stores, self.infoBox, item);
								self.infoBox.open(self.map, self.markers[item]);

								$('#repairCenterResult .list').not($(this).closest('.list')).removeClass('active');
								$(this).closest('.list').addClass('active');
							});
							self.$repairStoreList.find('.list .button-wrap a.see').off('click').on('click', function(e) {
								e.preventDefault();
								$(this).closest('.list').find('.dealer-name a').click();
							});

							if ($(window).width()<768) {
								GoogleMaps.$repairSection.addClass('active');
							} else {
								setTimeout(function() {
									GoogleMaps.$repairSection.addClass('active');
								}, 200);
							}
							GoogleMaps.$repairSection.addClass('active');
							self.zoom = self.map.getZoom();
							if (self.searchHandle && insetMarker < Math.min(2, data.length)) {
								var minBounds = new google.maps.LatLngBounds();
								for (var k = 0; k < Math.min(2, data.length); k++) {
									var _latlng = new google.maps.LatLng(String(data[k]['dealerLatitudeValue']), String(data[k]['dealerLongitudeValue']));
									minBounds.extend(_latlng);
								}
								setTimeout(function() {
									google.maps.event.addListenerOnce(self.map, 'bounds_changed', function(event) {
										if (self.map.getZoom() > 17) {
											self.map.setZoom(17);
										}
									});
									var _latlng = new google.maps.LatLng(self.map.getCenter().lat(), self.map.getCenter().lng());
									minBounds.extend(_latlng);
									self.map.fitBounds(minBounds);
									self.offsetCenter(self.markers[0].getPosition(), 0, 0, self.map);
								}, 350);
							} else if (self.searchHandle && insetMarker >= Math.min(2, data.length)) {
								self.offsetCenter(self.markers[0].getPosition(), 0, 0, self.map);
							}
							if ($(window).width()<768) self.slickEvent(self.$repairStoreList);

						} else {
							self.reset();
							self.setErrorList();
						}
						self.map.process = false;
					},
					complete: function() {
						self.searchHandle = false;
					}
				});
			},
			getStore : function(force) {
				var self = this;
				clearTimeout(self.timing.queryTimeout);
				//console.log(!self.infoBox.getVisible());
				if (!self.infoBox.getVisible() || force) {
					self.reset();
					self.timing.queryTimeout = setTimeout($.proxy(self.queryData, self), 200);
				}
			},
			setCountry : function(address) {
				var self = this;
				self.geocoder = new google.maps.Geocoder();
				var slocation = self.openDirection ? {
					"location": address
				} : {
					"address": address
				};

				self.geocoder.geocode(slocation, $.proxy(function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						self.geoResult.locations = results[0].geometry.location;
						self.offsetCenter(self.geoResult.locations, 220, -100, self.map);
						if ($(window).width()<768) self.offsetCenter(self.geoResult.locations, 100, -80, self.map);
					}
				}, this));
			},
			addMarkers : function(latlng, mark, itemNum) {
				var self = this;
				var bounds = new google.maps.LatLngBounds();
				var currMarker = self;

				var marker = new google.maps.Marker({
					map: self.map,
					icon: mark,
					draggable: false,
					position: latlng,
					zIndex: 1
				});

				marker.setVisible(true);
				self.markers.push(marker);

				google.maps.event.addListener(marker, "click", function(e) {
					self.infoBox.open(self.map, this);
					self.onItem(itemNum);
					self.focusList(itemNum);
				});

				google.maps.event.addListener(marker, 'mouseover', function() {
					this.setOptions({
						zIndex: 9
					});
				});
			},
			//moving map after markerk click
			offsetCenter : function(latlng, offsetx, offsety, map) {
				var self = this;
				var scale = Math.pow(2, map.getZoom());
				var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
				var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);
				var worldCoordinateNewCenter = new google.maps.Point(worldCoordinateCenter.x - pixelOffset.x, worldCoordinateCenter.y + pixelOffset.y);
				var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
				map.panTo(newCenter);
			},
			//map infoBox panel make 
			setStoreInfo : function(d, self, itemNum) {
				var data = d[itemNum];
				if (typeof(data['address']) != "string") data['address'] = "";
				if (typeof(data['dealerPhoneNo']) != "string") data['dealerPhoneNo'] = "";
				if (typeof(data['dealerEmail']) != "string") data['dealerEmail'] = "";
				if (typeof(data['dealerUrl']) != "string") data['dealerUrl'] = "";
				if (typeof(data['dealerTime']) != "string") data['dealerTime'] = "";

				var content = '';
				content+='<div class="info-window-wrap">';
				if(data['useSale'] || data['useInstall'] || data['useServices']) content+='<div class="icons">';
				if(data['useSale']) content+='<span class="icon-sales">sales</span>';
				if(data['useInstall']) content+='<span class="icon-install">install</span>';
				if(data['useServices']) content+='<span class="icon-service">service</span>';
				if(data['useSale'] || data['useInstall'] || data['useServices']) content+='</div>';
				content+='<div class="dealer-name">'+data['dealerName']+'</div>';
				if(data['dealerPhoneNo'] != "" && data['dealerPhoneNo'] != " ") content+='<div class="dealer-phonenumber"><a href="tel:'+data['dealerPhoneNo']+'">'+data['dealerPhoneNo']+'</a></div>';
				if(data['address'] != "") content+='<div class="dealer-address">'+data['address']+'</div>';
				if(data['dealerEmail'] != "") content+='<div class="dealer-email">'+data['dealerEmail']+'</div>';
				if(data['dealerUrl'] != "") content += '<div class="dealer-url"><a href="'+data['dealerUrl']+'" target="_blank" class="link-text ico-blank" >' + data['dealerUrl'] + '</a></div>';
				if(data['dealerTime'] != "") content += '<div class="dealer-time">' + data['dealerTime'] + '</div>';
				content+= "<div class='info-window-bottom'></div>";
				content+='</div>';
				self.setContent(content);
			},
			searchAddress : function(e) {
				if(!$('#repairProduct').val()) return false;
				var self = this,
					address = self.$searchInput.val();
				self.geocoder = new google.maps.Geocoder(); // add () phoebe
				e.preventDefault();
				if(!address || address=="") return false;
				self.geocoder.geocode({
					"address": address,
					"region": self.options.country
				}, function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						var geom = results[0].geometry,
							location = geom.location,
							address = results[0].formatted_address,
							viewport = geom.viewport;
						self.center = self.map.getCenter();
						self.results = results[0].address_components;
						self.zipcode = '';
						for (var i = self.results.length - 1; i > -1; i--) {
							self.address = self.results[i];
							if (self.address.types[0] == "postal_code") {
								self.zipcode = self.address.long_name;
								break;
							}
						}
						var markerOptions = {
							position: location,
							title: address,
							animation: google.maps.Animation.DROP
						};
						self.map.fitBounds(viewport);
						self.afterSearch();
						self.afterControl = true;
					} else {
						self.reset();
						self.setErrorList();
						self.afterControl = false;
					}
				});
				var slist = $(".dealer-list", self.$repairSection);
				setTimeout(function() {
					if($(window).width()<768) {
						if(slist.hasClass('mCustomScrollbar')) slist.mCustomScrollbar('disable', true);
					} else {
						//if(slist.hasClass('mCustomScrollbar')) slist.mCustomScrollbar('disable', false);
						if(slist.hasClass('mCustomScrollbar')) slist.mCustomScrollbar('update');
					}
				}, 500);
			},
			afterSearch : function() {
				var self = this;
				self.reset();
				self.searchHandle = true;
				//if (self.map.getZoom() < self.options.queryZoomLevel) {
				//	self.map.setZoom(self.options.queryZoomLevel);
				//} else {
				//	console.log('test2');
					self.distance = 25; // LGEUS-6101 : 20151002 modify
					self.getStore();
				//}
			},
			focusList : function(item, callback) {
				//console.log(item);
				if ($(window).width()<768) return;
				var slist = $(".dealer-list", self.$repairSection);
				slist.mCustomScrollbar("scrollTo", slist.find('.mCSB_container .list-box .list:eq('+item+')'));
			},
			onItem : function(item) {
				var self = this;
				$("div.list", "#repairCenterResult").removeClass("active").filter("div.list[data-item='" + item + "']").addClass("active");
				if (typeof(self.infoBox) != "undefined") {
					if (typeof(item) == "undefined" || item == "undefined") {
						self.infoBox.close();
						self.activeItem = null;
					} else if (self.activeItem != item) {
						self.infoBox.close();
						self.activeItem = item;
						if ($(window).width()>=768) {
							self.offsetCenter(self.markers[item].getPosition(), 220, -100, self.map);
						} else {
							self.offsetCenter(self.markers[item].getPosition(), 0, -100, self.map);
						}
						self.setStoreInfo(self.stores, self.infoBox, item);
						self.infoBox.open(self.map, self.markers[item]);
					} else if (self.activeItem == item && !self.infoBox.getVisible()) {
						self.infoBox.Content(null);
						self.offsetCenter(self.markers[item].getPosition(), 220, -100, self.map);
						self.setStoreInfo(self.stores, self.infoBox, item);
						self.infoBox.open(self.map, self.markers[item]);
					}
				}
			},
			//query string ascPostalCode make
			setRestrictionByGeocode : function(loc) {
				var self = this;
				self.geocoder.geocode({
					'location': loc
				}, function(results, status) {
					if (status == google.maps.GeocoderStatus.OK) {
						self.results = results[0].address_components;
						for (var i = self.results.length - 1; i > -1; i--) {
							self.address = self.results[i];
							if (self.address.types[0] == "postal_code") {
								self.zipcode = self.address.long_name;
								break;
							}
						}

					}
				});
			},
			clearMarker : function(map) {
				var self = this;
				for (var i = 0; i < self.markers.length; i++) {
					self.markers[i].setMap(null);
				}
				self.markers = [];
			},
			setRepairList : function(data, itemNum) {
				var self = this;
				var data = data[itemNum];
				var content = '';
				if (typeof(data['address']) != "string") data['address'] = "";
				if (typeof(data['dealerPhoneNo']) != "string") data['dealerPhoneNo'] = "";
				if (typeof(data['dealerEmail']) != "string") data['dealerEmail'] = "";
				if (typeof(data['dealerUrl']) != "string") data['dealerUrl'] = "";
				if (typeof(data['dealerTime']) != "string") data['dealerTime'] = "";

				content += '<div class="list" data-item="'+itemNum+'">';
				content += '<div class="dealer-wrap">';
				content += '<span class="number">'+(itemNum+1)+'</span>';
				if(data['useSale'] || data['useInstall'] || data['useServices']) content += '<div class="icons">';
				if(data['useSale']) content += '<span class="icon-sales">sales</span>';
				if(data['useInstall']) content += '<span class="icon-install">install</span>';
				if(data['useServices']) content += '<span class="icon-service">service</span>';
				if(data['useSale'] || data['useInstall'] || data['useServices']) content += '</div>';
				content += '<div class="dealer-name"><a href="#" data-item="'+itemNum+'">'+data['dealerName']+'</a></div>';
				//if(data['dealerPhoneNo'] != "" && data['dealerPhoneNo'] != " ") content += '<div class="dealer-phonenumber">Phone : '+data['dealerPhoneNo']+'</div>';
				if(data['address'] != "") content += '<div class="dealer-address">'+data['address']+'</div>';
				//if(data['dealerEmail'] != "") content += '<div class="dealer-email">'+data['dealerEmail']+'</div>';
				//if(data['dealerUrl'] != "") content += '<div class="dealer-url"><a href="'+data['dealerUrl']+'" target="_blank">' + data['dealerUrl'] + '</a></div>';
				//if(data['dealerTime'] != "") content += '<div class="dealer-time">' + data['dealerTime'] + '</div>';
				content += '</div>';
				content += '<div class="button-wrap">';
				if(data['dealerPhoneNo'] != "" && data['dealerPhoneNo'] != " ") content += '<a href="tel:'+data['dealerPhoneNo']+'" class="btn btn-primary">CALL</a>';
				content += '<a href="#none" class="btn btn-outline-secondary see">SEE</a>';
				content += '</div>';
				content += '</div>';
				self.$repairStoreList.append(content);
			},
			setErrorList : function() {
				var self = this;
				if (self.$searchInput.val() != "") {
					// self.reset();
					GoogleMaps.$repairStoreList.parent().find('.no-items').addClass('active');
					setTimeout(function() {
						GoogleMaps.$repairSection.addClass('active');
					}, 200);
				}
			},
			//center distance tap event
			distanceHandler : function(e) {
				var self = this;
				var target = $(e.target);
				if (self.afterControl) {
					//self.distance = target.parent().data("distance");
					self.distance = 25;
					self.getStore(true); // force:true
					e.preventDefault();
				}
			},
			reset : function() {
				var self = this;
				self.clearMarker();
				self.onItem();
				self.activeItem = null;

				if (typeof(self.infoBox) != "undefined") self.infoBox.close();
				self.infoBox.setContent(null);
				self.directionsDisplay.setMap(null);
				self.directionsDisplay.setPanel(null);
				self.$repairStoreList.html('');
				self.$repairSection.addClass("active");
				self.$repairStoreList.parent().find('.no-items').removeClass('active');
				self.$repairStoreList.parent().find('.btn-area').removeClass('active');

				if(self.$repairStoreList.hasClass('slick-slider') || self.$repairStoreList.hasClass('slick-initialized')) {
					self.$repairStoreList.slick('unslick');
					self.$repairStoreList.removeClass('slick-slider').removeClass('slick-initialized');
				}
			},
			resizing : function() {
				var self = this;
				self.center = self.map.getCenter();
				google.maps.event.trigger(self.map, "resize");
				self.map.setCenter(self.center);
			},
			//mobile only map size
			mapExpand : function(e) {
				var self = this;
				var tHeight = self.$repairMapArea.height();
				var wHeight = window.innerHeight;

				self.zoom = self.map.getZoom();
				e.preventDefault();
				if (tHeight == 180) {
					self.$repairMapArea.stop().animate({
						"height": "100%"
					}, 300, function() {
						$(e.target).addClass("on");
						self.$repairMapArea.parent().addClass("full");
						self.resizing();

					});
				} else {
					self.$repairMapArea.stop().animate({
						"height": 180
					}, 300, function() {
						$(e.target).removeClass("on");
						self.$repairMapArea.parent().removeClass("full");
						self.resizing();

					});
				}
			},
			slickEvent: function(ele) {
				//console.log(this.$repairStoreList.html());
				this.$repairStoreList.parent().find('.no-items').removeClass('active');
				//this.$repairStoreList.parent().find('.btn-area').addClass('active');
				setTimeout(function() {
					ele.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
						var i = (currentSlide ? currentSlide : 0) + 1;
						$(".C0020 .total-page").text(slick.slideCount);
						$(".C0020 .current-page").text(i);
					});
					ele.slick({
						adaptiveHeight: true,
						rows: 3,
						slidesPerRow: 1,
						infinite: false,
						dots: false,
						speed: 500,
						prevArrow: ".pagination .prev",
						nextArrow: ".pagination .next"
					}).next(".btn-area").addClass('active');
					
				}, 1000);
			}
		};

		GoogleMaps._init();
;
	};

	if (isGoogleMap != null) {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		// script.src = '//maps.googleapis.com/maps/api/js?v=3.33&libraries=places&sensor=true&client=gme%2Dlgelectronicsinc1&signature=z1EbnXIiQ0aq1744IJpOz3LTTOc=&callback=initialize&region=KR&language=' + document.documentElement.lang;
		script.src = '//maps.googleapis.com/maps/api/js?v=3.33&libraries=places&client=gme%2Dlgelectronicsinc1&callback=initialize&region=KR&language=' + document.documentElement.lang;
		document.body.appendChild(script);
		// change
		// v=3.17 > 3.33
		// delete &sensor=true
		// delete &signature=z1EbnXIiQ0aq1744IJpOz3LTTOc=
	}
	
	var infoBlock = $('.find-service-center.detail .collapse-detail a');
	var controlIB = function(e) {
		if (!e.matches) {
			if (infoBlock.hasClass('collapsed')) {
				infoBlock.trigger('click');
			}
			// 
		}
	};
	mql.maxSm.addListener(controlIB);
	controlIB(mql.maxSm); 
});
/* jshint ignore:end */