$(document).ready(function() {
	if(!document.querySelector('.C0072')) return false;

	var boxWidth;
	var isMobile;
	var fix72Tables= {
		$el: $('.C0072'),
		init: function() {
			this.$box = this.$el.find('.tbl-box');
			$(window).resize(function() {
				boxWidth = fix72Tables.$box.outerWidth();
				isMobile = (fix72Tables.$el.eq(0).find('.desktop-display').css('display') != 'none') ? false:true;
				fix72Tables.$el.each(function() {
					fix72Tables.fixSize($(this));
				});
			});
			this.$el.each(function() {
				boxWidth = fix72Tables.$box.outerWidth();
				fix72Tables.fixSize($(this));
				$(this).find('.quiver > button').on('click', function(e) {
					e.preventDefault();
					var scrollObj = $(this).closest('.component').find('.desktop-display.tbl-box');
					var scrollLenth = $(this).closest('.component').data('danWidth');
					//console.log('scrollLenth', scrollLenth);
					if($(this).hasClass('prev-arrow')) {
						scrollObj.mCustomScrollbar('scrollTo','+='+scrollLenth);
					} else {
						scrollObj.mCustomScrollbar('scrollTo','-='+scrollLenth);
					}
				});
			});
		},
		fixSize : function($obj) {
			if(!isMobile) {
				var table = $obj.find('.desktop-display table.comp-tbl');
				var dan =table.find('tr').eq(0).find('td, th').length;
				var ww = $(window).width();
				var danWidth = 0;
				var allWidth = 0;
				var scrollObj = $obj.find('.tbl-box');
				var danStandard = scrollObj.width();
				var controls = $obj.find('.quiver > button');
				if(ww>992) {
					// more than five columns, show scroll
					danWidth = parseInt(danStandard/5);
				} else {
					// more than three columns, show scroll
					danWidth = parseInt(danStandard/3);
				}
				//console.log('dan', danWidth);
				$obj.data('danWidth', danWidth);
				allWidth = danWidth*dan;
				if(danStandard<allWidth) {
					// use scroll
					table.css('width',allWidth);
					scrollObj.mCustomScrollbar("update");
					controls.addClass('active');
					$obj.addClass('scroll');
				} else {
					// donot use scroll
					table.css('width',danStandard);
					scrollObj.mCustomScrollbar("disable");
					controls.removeClass('active');
					$obj.removeClass('scroll');
				}
			} else {
				$obj.removeClass('scroll');
			}
		}
	};
	fix72Tables.init();
});
