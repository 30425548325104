$(document).ready(function() {
	if(!document.querySelector('.C0056')) return false;

	var componentAnimateObj = $('.C0056');
	
	componentAnimateObj.each(function(){
		var visualObj = $(this).find('.visual-area');
		var visualTarget = visualObj.find('.show-cut');
		var playTimer = 1000;
		var easing = 'easeOutCubic';
		$(window).resize(function(){
			$(window).scroll(function(){
				var windowTop = $(window).scrollTop();
				var windowHeight = $(window).height();
				var brw_btm = (windowTop + windowHeight);
				var positionFix = visualObj.height()/2;
				var objPosition = visualObj.offset().top + positionFix;
				if ( windowTop < objPosition && objPosition < brw_btm ) {
					if (visualObj.hasClass('show-cut-bt') && !visualObj.hasClass('animated'))
					{
						visualObj.addClass('animated');
						var visualTargetHeight = visualTarget.height();
						var visualTargetHeightMovePos = -(visualTargetHeight/2);
						visualTarget.stop().animate({
							top:'50%',
							marginTop:visualTargetHeightMovePos
						},playTimer,easing,function(){
							visualTarget.addClass('center');
						});
					} else if ( visualObj.hasClass('show-cut-rl') && !visualObj.hasClass('animated') )
					{
						visualObj.addClass('animated');
						var visualTargetWidth = visualTarget.width();
						var visualTargetWidthMovePos = -(visualTargetWidth/2);
						visualTarget.stop().animate({
							left:'50%',
							marginLeft:visualTargetWidthMovePos
						},playTimer,easing,function(){
							visualTarget.addClass('center');
						});
					} else {
						return false;
					}
				}
			});
		}).resize();
	});
});