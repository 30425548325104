$(document).ready(function() {
	if(!document.querySelector('.C0042')) return false;	

	var component_01 = function(){
		var p = component_01.prototype;

		p.init = function(){
			this.eventOn();
		};

		p.eventOn = function(){

			// accordian open event
			this.binderElem.$open.on("click", function(e){
				e.preventDefault();

				// open
				$(this).closest(".component").children(".desc").addClass("bg-gray");
				$(this).closest(".list-btn-area").next(".more-content").addClass("open").slideDown(400);

				// find scroll line
				var tg = $(this).closest(".component"),
					adjustOption = ($(".navigation").length>0) ? Math.round($(".navigation").outerHeight()) : 0,
					contTop = tg.find(".more-content").offset().top,
					contAdjustTop = Math.round(contTop - adjustOption);

				// scrolling 
				p.scrolling(contAdjustTop);
			});

			// accordian close event
			this.binderElem.$close.on("click", function(e){
				e.preventDefault();

				// close
				$(this).closest(".component").children(".desc").removeClass("bg-gray");
				$(this).closest(".more-content").removeClass("open").slideUp(400);

				// find scroll line
				var tg = $(this).closest(".component"),
					adjustOption = ($(".navigation").length>0) ? Math.round($(".navigation").outerHeight()) : 0,
					tgTop = tg.offset().top,
					tgAdjustTop = Math.round(tgTop - adjustOption);

				// scrolling
				p.scrolling(tgAdjustTop);
				
			});

			// scroll event
			$(window).on("scroll", function(){
				var scrollPos = $(window).scrollTop(),
					lineCheck = 1200; // temporary

				p.gnbStickToLine( (scrollPos > lineCheck) );
			});
		};

		p.theElem = $("div[class*=\"C0042\"]");
		
		p.binderElem = {
			$open : p.theElem.find(".list-btn-area").children(".more"),
			$close : p.theElem.find(".in-list-btn-area").children(".in-close")
		};

		p.scrolling = function(st){
			$("html, body").stop().animate({
				scrollTop : st
			},400);
		};
		
		p.gnbStickToLine = function(condition){
			if(condition){
				$(".temp-gnb").addClass("fixed");
			}else{
				$(".temp-gnb").removeClass("fixed");
			}
		};

		p.init();
	};
	component_01();
});


