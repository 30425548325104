$(document).ready(function() {
	if(!document.querySelector('.pdp-layer-tab-object')) return false;
	

	var $specObj = $('.pdp-layer-tab-object');

	$specObj.each(function(){
		var $specsTabs = $(this).find('.pdp-layer-tab-desktop-list');
		var $specsTabsm = $(this).find('.obj-menu-list');
		var $specsObjSpec = $(this).find('.obj-current-menu');
		
		$specsTabs.find('a').on('click', function(e) {
			// click tabs
			var specsTarget = $(this).data('id');
			var $parentSpec = $(this).parents('.pdp-layer-tab-desktop-list');

			// change tab design
			$parentSpec.find('a').not($(this)).removeClass('active');
			$(this).addClass('active');
			// change id
			$(this).parents('.pdp-layer-tab-object').find('.pdp-conts-area').removeClass('active');
			$(this).parents('.pdp-layer-tab-object').find('#'+specsTarget).addClass('active');
			
			var index = $(this).index();
			$specsTabsm.find('li').eq(index).find('a').trigger('click');
			return false;
		});

		//select sign

		$specsObjSpec.click(function(){
			var exOffsetTop = $specsObjSpec.find('.current-menu').outerHeight();
			if($(this).hasClass('off')) {
				$(this).removeClass('off');
				$specsTabsm.addClass('active');
			} else {
				$(this).addClass('off');
				$specsTabsm.removeClass('active');
			}
			// $specsObjSpec.css({minHeight:'none'});
			// var offsetTop = $specsObjSpec.find('.current-menu').outerHeight();
			// $specsTabsm.css({top:offsetTop-exOffsetTop});
			return false;
		});

		$specsTabsm.find('a').off('click').on('click',function(){
			var index = $(this).parent().index();
			$specsTabs.find('a').removeClass('active');
			$specsTabs.find('a').eq(index).addClass('active');
			var cloneTxt = $(this).find('strong').text();
			var cloneImg = $(this).find('img').clone();
			var dataID = $(this).data('id');
			
			$(this).parents('.pdp-layer-tab-object').find('.pdp-conts-area').removeClass('active');
			$(this).parents('.pdp-layer-tab-object').find('#'+dataID).addClass('active');
			$specsObjSpec.find('.current-menu').find('span img').remove();
			$specsObjSpec.find('.current-menu').find('span').prepend(cloneImg);
			$specsObjSpec.find('.current-menu').find('em').empty().text(cloneTxt);
			$specsObjSpec.addClass('off');
			$specsTabsm.removeClass('active');
			return false;
		});

		

		var mouseEvent = function(){
			$specsTabs.find('a').on('mouseover',function(){
				var windowWidth = $(window).width();
				if (windowWidth>767)
				{
					var gap = $(this).find('span').position().top;
					var minHeight = $(this).outerHeight(true)+2;
					$(this).find('.overlayer').css({
						paddingTop:gap,
						minHeight:minHeight,
					}).show();
				} else {
					return false;
				}
			});

			$specsTabs.find('a').on('mouseout',function(){
				var windowWidth = $(window).width();
				if (windowWidth>767)
				{
					$(this).find('.overlayer').css({
						paddingTop:0,
						minHeight:0
					}).hide();
				} else {
					return false;
				}
			});
		};
		
		mouseEvent();
	});
	

});