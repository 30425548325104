var compareCookie = {
	name: 'LG5_CompareCart',
	add: function(productId){
		var cookie = getCookie(this.name);
		if(cookie) {
			cookie = cookie.indexOf('|') >= 0 ? cookie.split('|') : [cookie];

			if(cookie.indexOf(productId) < 0) {
				cookie.unshift(productId);
			}
			cookie = cookie.join('|');
		}else {
			cookie = productId;
		}
		
		setCookie(this.name, cookie, true);
	},
	remove: function(productId){
		if(productId) {
			var cookie = getCookie(this.name);
			if(cookie) {
				var idx;
				cookie = cookie.indexOf('|') >= 0 ? cookie.split('|') : [cookie];
				idx = cookie.indexOf(productId);
				if(idx >= 0) {
					cookie.splice(idx, 1);
				}
				cookie = cookie.join('|');
			}
		}else {
			cookie = '';
		}
		

		if(cookie == '' || !cookie) {
			removeCookie(this.name, true);
		}else {
			setCookie(this.name, cookie, true);
		}
	}
};