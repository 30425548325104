$(document).ready(function() {
	if(!document.querySelector('.js-ajax-tab-page')) return false;

	var c = {
		el: null,
		tab : null,
		init: function(){
			var _this = c;

			var els = document.querySelectorAll('.js-ajax-tab-page');

			for (var i = 0; i < els.length; i++) {
				_this.el = els[i];
				_this.tab = _this.el.querySelector('.tabs-type-liner');
				_this.addEvent();
				if(_this.tab && !$(_this.tab.querySelector('a')).is('.active')) {
					$(_this.tab.querySelector('a')).trigger('click');
				}else {
					$(_this.el.querySelector('form')).submit();
				}
			}
		},
		addEvent: function(){
			var _this = c;
			$(_this.el).on({
				submit: function(e){
					e.preventDefault();
					var isExpander = $(e.delegateTarget).data('focus') == true;
					var url = isExpander ? e.currentTarget.getAttribute('data-more-list') : e.currentTarget.action,
						param = xssfilter($(e.currentTarget).serialize());
					var $target = $(e.currentTarget).siblings('.list-contents-wrap');

					ajax.call(url, param, 'html', function(data){
						if(data) {
							if(isExpander) {
								var expanderBtn = $(data).find('.expander').html();
								data = $(data).find('.unit-list').html();
								$target.find('.unit-list').append(data);
								$target.find('.expander').append(expanderBtn);
								// $(_this.el.querySelector('.expander button')).focus();
								// console.log(data);
							}else {
								$target.html(data);
								if($target.hasClass('initialized')) {
									$('html, body').animate({
										scrollTop: $target.closest('.component').offset().top
									});
								}
							}

							$target.addClass('initialized');
						}
					});
				}
			}, 'form');

			$(_this.tab).on({
				click: function(e){
					e.preventDefault();
					var target = $(e.currentTarget.getAttribute('href')).get(0),
						targetForm = target.querySelector('form');

					if(!$(target.querySelector('.list-contents-wrap')).is('.initialized')) {
						$(targetForm).submit();
					}
				}
			}, 'a');

			$(_this.el).on({
				click: function(e){
					e.preventDefault();
					var page = e.currentTarget.value,
						targetForm = $(e.currentTarget).closest('.contents-area').find('form').get(0),
						_del = e.delegateTarget;

					targetForm.page.value = page;

					if($(e.currentTarget).closest('.expander')[0]) {
						$(_del).data('focus', true);
						$(e.currentTarget).remove();
					}else {
						$(_del).data('focus', false);
					}

					$(targetForm).submit();
				}
			}, '.pagination button, .expander button');
		}
	}

	c.init();
});