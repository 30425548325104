$(document).ready(function() {
	if(!document.querySelector('.animation-box')) return false;

	var ani = {
		el: document.querySelectorAll('.animation-box'),
		init: function(){
			ani.addEvent();
		},
		addEvent: function(){
			//
			$(ani.el).on({
				click: function(e){
					var button = e.currentTarget,
						box = $(e.delegateTarget).find('video')[0];
						console.log();

					if(button.name == 'pause') {
						box.pause();
					}else if (button.name == 'play') {
						box.play();
					}
				}
			}, 'button');
			$(ani.el).find('video').on({
				'play playing pause ended': function(e){
					var video = e.currentTarget,
						$ctrl = $(video).siblings('.controller-wrap');

					if(e.type == 'ended' || e.type == 'pause') {
						$ctrl.find('.play').addClass('active').siblings().removeClass('active');
					}else if(e.type == 'play' || e.type == 'playing') {
						$ctrl.find('.pause').addClass('active').siblings().removeClass('active');
					}
				}
			});
		}
	};

	ani.init();
});
