$(document).ready(function() {
	// 현재 없어도 plp-model-switcher.js에 의해 발생할 수 있음.
	//if(!document.querySelector('.js-thumbnail-loop')) return false;

	var thumbnailLoop = {
		// C0007 C0026 (#resultProductList), C0004 (.products-list-wrap), myLG>My Products(#resultAppendTarget), search result (.search-result-products-wrap), search result all (.product-list-box)
		list: $('#resultProductList, .products-list-wrap, #resultAppendTarget, .search-result-products-wrap, .product-list-box'),
		interval: null,
		idx: 1,
		loop:function(img, imgList){
			var src = imgList[thumbnailLoop.idx];
			if((imgList.length-1) > thumbnailLoop.idx){
				thumbnailLoop.idx++;
			}else {
				thumbnailLoop.idx = 1;
			}
			img.src = src;
		},
		hover: function(e){
			var _img = e.currentTarget,
				imgList = _img.getAttribute('data-img-list').split(',');

			clearInterval(thumbnailLoop.interval);
			thumbnailLoop.interval = setInterval(function(){
				thumbnailLoop.loop(_img, imgList);
			}, 1000);
		},
		blur: function(e){
			var _img = e.currentTarget,
				imgList = _img.getAttribute('data-img-list').split(',');
			clearInterval(thumbnailLoop.interval);
			thumbnailLoop.idx = 1;
			_img.src = imgList[0];
		},
		init: function(){
			this.addEvent();
		},
		addEvent: function(){
			var _this = this;
			$(_this.list).on({
				'mouseenter focus': _this.hover,
				'mouseleave focusout': _this.blur
			}, '.js-thumbnail-loop');
		}
	}
	thumbnailLoop.init();
});
