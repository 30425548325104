$(document).ready(function() {
	if(!document.querySelector('.C0068')) return false;

	
	var componentCarouselSimple = {
		$el : null,
		init : function() {
			this.$el = $('.C0068');
			this.$el.find('.carousel-wrap-box').each(function(){
				//console.log($(this).find('.carousel-wrap .carousel-box').length);
				if($(this).find('.carousel-wrap .carousel-box').length>1) {
					componentCarouselSimple.runSlick($(this));
				}
			});
		},
		runSlick: function($obj) {
			var $slick = $obj.find('.carousel-wrap').slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : true,
				dots: false,
				//adaptiveHeight: true,
				lazyLoad: 'ondemand',
				//autoplay: true,
				//autoplaySpeed: 10000,
				prevArrow: carouselOptions.bigAnglePrev, // common.js variable
				nextArrow: carouselOptions.bigAngleNext, // common.js variable
				appendDots:$obj.find('.slick-dot-wrap'),
				responsive: [
					{
					  breakpoint: 768,
					  settings: {
						dots: true
					 }
					}
				]
			});
		}
	};
	componentCarouselSimple.init();
	
});