$(document).ready(function() {
	if(!document.querySelector('.C0047')) return false;

	var $obj = $('.C0047');
	$obj.each(function() {
		if($(this).find('.carousel-box .list-box .items .item').length>0) { // do not modify
			var $slick = $(this).find('.carousel-box .list-box .items').slick({
				infinite: false,
				slidesToShow: 6,
				slidesToScroll: 1,
				arrows : true,
				dots: false,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							autoplay: false,
							slidesToShow: 5,
							slidesToScroll: 1,
							arrows : true
						}
					},
					{
						breakpoint: 992,
						settings: {
							autoplay: false,
							slidesToShow: 3,
							slidesToScroll: 1,
							arrows : true
						}
					},
					{
						breakpoint: 768,
						settings: {
							autoplay: false,
							slidesToShow: 1,
							slidesToScroll: 1,
							dots: true,
							arrows: false
						}
					}
				],
				prevArrow: carouselOptions.bigAnglePrev, // common.js variable
				nextArrow: carouselOptions.bigAngleNext // common.js variable
			});
		}
	});
});
