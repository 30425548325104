$(document).ready(function() {
	if(!document.querySelector('.C0053')) return false;
	$('.C0053').each(function() {
		var anchor = $(this).find('.visual-set .thumbnail-area a');
		anchor.on('click', function(e) {
			e.preventDefault();

			// change text
			var text = $(this).closest('.obj-thumb').find('.text-area-default').html();
			$(this).closest('.C0053').find('.text-area').html(text);
			
			// change button box
			var btnBox = $(this).closest('.obj-thumb').find('.button-box-default').html();
			$(this).closest('.C0053').find('.btn-type-box').html(btnBox);
			// change button text
			var btnText = $(this).closest('.obj-thumb').find('.button-text-default').html();
			$(this).closest('.C0053').find('.btn-type-text').html(btnText);

			// make large image tag
			var html = '';
			var largeImgURL = $(this).find('img').data('large');
			var largeArea = $(this).closest('.visual-set').find('.visual-area');

			if($(this).data('type') == 'youtube') {
				// youtube type
				videoID = $(this).data('video-id');
				alt = $(this).find('img').attr('alt');
				html = '<a href="#none" data-src="https://www.youtube.com/embed/'+videoID+'" class="see-video" data-type="youtube" data-target="modal"><img src="'+largeImgURL+'" alt="" /><div class="caption">'+alt+'</div></a>';
				largeArea.addClass('video');
			} else if($(this).data('type') == 'brightcove') {
				// brightcove type
				videoID = $(this).data('video-id');
				account = $(this).data('account');
				player = $(this).data('player');
				alt = $(this).find('img').attr('alt');
				html = '<a href="#none" class="see-video" data-type="brightcove" data-video-id="'+videoID+'" data-account="'+account+'" data-player="'+player+'" data-target="modal"><img src="'+largeImgURL+'" alt="" /><div class="caption">'+alt+'</div></a>';
				largeArea.addClass('video');
			} else {
				// image type
				alt = $(this).find('img').attr('alt');
				html = '<img src="'+largeImgURL+'" alt="'+alt+'" />';
				largeArea.removeClass('video');
			}
			largeArea.html(html);

			// change active class
			$(this).closest('.thumbnail-area').find('.obj-thumb a').removeClass('active');
			$(this).addClass('active');
		});

		var $slick = $(this).find('.visual-m-area .items').slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			arrows : true,
			dots: true,
			responsive: [{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows : false
				}
			}],
			prevArrow: carouselOptions.bigAnglePrev, // common.js variable
			nextArrow: carouselOptions.bigAngleNext // common.js variable
		});
	});
});