$(document).ready(function() {
	if(!document.querySelector('.C0064')) return false;

	var socialComponent = function(){
		var slideContainer = $('.C0064');
		var slideTarget = slideContainer.find('.block-wrap');
		var slideAutoplayBtn = slideContainer.find('.slick-indicator');
		var slideAutoplayBtnObject = slideAutoplayBtn.find('.autoplay');
		
		
		slideTarget.slick({
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			nextArrow:slideContainer.find('.social-next'),
			prevArrow:slideContainer.find('.social-prev'),
			dots:true,
			appendDots:slideContainer.find('.slick-dot-wrap'),
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
				  breakpoint: 768,
				  settings: {
					autoplay: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false
				  }
				}
			]
		});

	};

	socialComponent();

	
});
