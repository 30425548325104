$(document).ready(function() {
	if(!document.querySelector('.C0066')) return false;

	var target = $('.C0066');
	var tableCollapse = function(){	
		var btn = target.find('.btn-collapse a');
		var tableBtm = target.find('.table-bottom');
		var tableObj = target.find('.information-table');
		btn.on('click',function(){
			if ($(this).parent().hasClass('btn-close'))
			{
				$(this).parent().removeClass('btn-close').addClass('open');
				$(this).addClass('active');
				$(this).text($(this).attr('data-open-text'));
				var collapseHeight = parseInt(tableObj.outerHeight(true)-tableBtm.outerHeight(true)-1);
				tableObj.stop().animate({height:collapseHeight},400,"easeOutQuad",function(){
					tableBtm.hide();
					tableObj.css({
						height:'auto'
					});
				});
			} else {
				$(this).parent().removeClass('open').addClass('btn-close');
				$(this).removeClass('active');
				$(this).text($(this).attr('data-close-text'));
				tableObj.css({
					height:tableObj.outerHeight(true)
				});
				tableBtm.show();
				var collapseHeight = parseInt(tableObj.outerHeight(true)+tableBtm.outerHeight(true)+1);
				tableObj.stop().animate({height:collapseHeight},400,"easeOutQuad",function(){
					tableObj.css({
						height:'auto'
					});
				});
			}
		});	
	};

	var mobileTableSetting = function(){
		var targetTD = target.find('.chart-table td');
		var targetTDs = target.find('.floating-table .table-wrap td');
		var targetBtn = target.find('.visible-m .collapse-btn');
		var _arr = [];
		$(window).resize(function(){
			
			if (!targetBtn.hasClass('open'))
			{
				clearTimeout(timer);
				var timer = setTimeout(function(){
					targetTD.each(function(){
						_arr = [];
						var tgh = $(this).find('.product-name').outerHeight(true);
						var tgh2 = $(this).find('.product-box').outerHeight(true);
						var tghC = tgh + tgh2;
						_arr.push(tghC);
						_arr.sort(function(a, b){return b - a});
					});
					targetTD.height((_arr[0]));
					targetTDs.height((_arr[0]-36)); 
				},100);
			} else {
				return false;
			}
		}).resize();
	};

	var tableScrollWidth = function(){
		
		var targetBox = target.find('.floating-table .position-wrap');
		var targetTdLength = target.find('.floating-table .table-wrap thead th').length;
		var targetTdWidth = target.find('.floating-table .table-wrap thead th:first-child').outerWidth(true);
		targetBox.width(targetTdLength*targetTdWidth);
	};

	var floatingTableBtn = function(){
		var targetBtn = target.find('.visible-m .collapse-btn');
		var targetImg = target.find('.visible-m .chart-table .item-image');
		var targetBox = target.find('.floating-table');
		targetBtn.on('click',function(){
			if ($(this).hasClass('open'))
			{
				$(this).addClass('btn-close').removeClass('open');
				targetBox.removeClass('active');
				//targetBox.stop().animate({left:'71.875%',width:'29.125%'},200);
				targetImg.show();
				$(window).resize();
			} else {
				$(this).addClass('open').removeClass('btn-close');
				targetBox.addClass('active');
				//targetBox.stop().animate({left:'50%',width:'50%'},200);
				targetImg.hide();
			};
			return false;
		});
	};

	var swipeTableDetect = function(){
		var targetBox = target.find('.floating-table');
		var targetBtn = target.find('.visible-m .collapse-btn');
		targetBox.scroll(function(){
			if ($(this).scrollLeft() > 0)
			{
				$(this).attr('data-flag',1);
			} else if ($(this).scrollLeft() == 0) {
				$(this).attr('data-flag',0);
				targetBtn.trigger('click');
			};
		});
	};

	

	tableCollapse();
	mobileTableSetting();
	floatingTableBtn();
	swipeTableDetect();

	var screenDetect = function(e) {
		if (e.matches) {
			tableScrollWidth();
		}
	};

	mql.maxSm.addListener(screenDetect);
	screenDetect(mql.maxSm);
});