$(document).ready(function() {
	if(!document.querySelector('.C0022')) return false;
	var compareComponent = {
		$el: $('.C0022'),
		alert: document.getElementById('compare_alert'),
		cookieArray: null,
		maximum: null,
		url: null,
		// return markup
		markup: {
			blank: null,
			item: null
		},
		init: function(){
			this.markup.blank = compareComponent.$el.find('template.empty-item').clone().html();
			this.markup.item = compareComponent.$el.find('template.product-item').clone().html();
			this.url = compareComponent.$el.attr('data-compare-url');
			this.cookie = compareCookie;
			this.maximum = compareComponent.$el.attr('data-type-plp') == 'true' ? 6 : 3;

			this.slick.init();

			if(document.querySelector('.js-compare')) {
				this.trigger.init();
				$('.js-compare').each(function(){
					var addTxt = $(this).find('.add.sr-only').text();
					$(this).attr('title',addTxt);
				});
			}
		},
		//
		switcher: function(e){
			e.preventDefault();
			var _this = e.currentTarget,
				status = $(_this).hasClass('added'),
				productId = _this.getAttribute('data-product-id'),
				url = compareComponent.url,
				param = xssfilter(JSON.stringify($(_this).data()));

			var addTxt = $(_this).find('.add.sr-only').text();
			var removeTxt = $(_this).find('.remove.sr-only').text();

			if(!status) {
				var maximum = compareComponent.maximum,
					cookie = getCookie(compareComponent.cookie.name),
					count;
				if(cookie) {
					cookie = cookie.indexOf('|') >= 0 ? cookie.split('|') : [cookie];
					count = cookie.length+1;
				}else {
					count = 1;
				}
				console.log(maximum, count);
					
				if(cookie && maximum < count){
					$(compareComponent.alert).find('.number').text(maximum);
					$(compareComponent.alert).find('.maximum').show().siblings().hide();
					$(compareComponent.alert).modal('show');
				}else {
					ajax.noCacheCall(url, param, 'json', function(data){
						if(data.result == true) {
							compareComponent.slick.addItem(productId, data.product);
							compareComponent.cookie.add(productId);

							compareComponent.$el.find('.count').text(count);
							$(_this).addClass('added').attr('title',removeTxt);

							var currentPLP = compareComponent.$el.attr('data-type-plp');

							if(data.product.isPLP != currentPLP) {
								compareComponent.$el.attr('data-type-plp', data.product.isPLP);
								compareComponent.maximum = data.product.isPLP == true ? 6 : 3;

								console.log(compareComponent.maximum);
							}
						}else {
							$(compareComponent.alert).find('.clear-category').show().siblings().hide();
							$(compareComponent.alert).modal('show');
						}
					});
				}
			}else {
				compareComponent.remove(productId);
				$(_this).removeClass('added').attr('title',addTxt);
			}
		},
		remove: function(productId){
			var cookie = getCookie(compareComponent.cookie.name),
				count;
			if(cookie) {
				cookie = cookie.indexOf('|') >= 0 ? cookie.split('|') : [cookie];
				count = cookie.length-1;
			}else {
				count = 0;
			}
			compareComponent.slick.removeItem(productId);
			compareComponent.cookie.remove(productId);
			compareComponent.$el.find('.count').text(count);
		},
		// compare Sticky bottom layer
		slick: {
			$slideTarget: null,
			opt: {
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: true,
				dots: false,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
							arrows: false
						}
					}
				]
			},
			init: function(){
				var _this = compareComponent.slick,
					$el = compareComponent.$el;

				_this.$slideTarget = $el.find('.compare-product');
				_this.$slideTarget.slick(_this.opt);

				mql.maxMd.addListener(_this.branch);
				_this.setMobileDisabled(); // change desktop and mobile item count

				// remove button
				_this.$slideTarget.on({
					click: function(e){
						var id = $(e.currentTarget).closest('.item').attr('data-product-id');
						compareComponent.remove(id);
					}
				}, '.close');

				// clear button
				$el.on({
					click: function(e){
						console.log(e);
						_this.$slideTarget.slick('unslick');
						_this.$slideTarget.html('');

						var html = [];
						for (var i = 0; i < 3; i++) {
							html += compareComponent.markup.blank;
						}
						_this.$slideTarget.html(html);
						_this.$slideTarget.slick(_this.opt);
						compareComponent.cookie.remove();
						$('.js-compare').removeClass('added');
						$('.js-compare').each(function(){
							var addTxt = $(this).find('.add.sr-only').text();
							$(this).attr('title',addTxt);
						});
						$el.find('.count').text(0);
						_this.switchLayer(false);
					}
				}, '.clear');
			},
			setMobileDisabled: function(){
				var _this = compareComponent.slick;
				var $thirdItem = _this.$slideTarget.find('.item:eq(2)');
				if($thirdItem.is('.no-item')) {
					$thirdItem.addClass('mobile-disable');
				}
				_this.branch(mql.maxMd);
			},
			branch: function(e){
				var _this = compareComponent.slick;
				if (e.matches) { // mobile
					_this.$slideTarget.slick('slickFilter',':not(.mobile-disable)');
				}else {
					_this.$slideTarget.slick('slickUnfilter');
				}
			},
			setBlank: function(){
				var _this = compareComponent.slick,
					count = _this.$slideTarget.find('.product').length,
					html = [],
					loop = 0;

				var blank = _this.$slideTarget.find('.no-item');
				if(blank[0]) {
					for (var i = 0; i < blank.length; i++) {
						var idx = _this.$slideTarget.slick("getSlick").slideCount - 1;
						_this.$slideTarget.slick('slickRemove', idx);
					}
				}
				if(count<3) {
					loop = (3 - count);
					for (var j = 0; j < loop; j++) {
						_this.$slideTarget.slick('slickAdd', compareComponent.markup.blank);
					}
				}

				_this.setMobileDisabled();
			},
			// add product item
			addItem: function(productId, data){
				var _this = compareComponent.slick,
					markup = compareComponent.markup.item;
				markup = markup.replace(/\*modelId\*/g, productId)
							.replace(/\*modelName\*/g, data.modelName)
							.replace(/\*thumbnail\*/g, data.thumbnail)
							.replace(/\*url\*/g, data.url)
							.replace(/\*promotionPrice\*/g, data.promotionPrice)
							.replace(/\*normalPrice\*/g, data.normalPrice)
							.replace(/\*legalText\*/g, data.legalText);
				_this.$slideTarget.slick('slickAdd', markup, 0, true);
				_this.setBlank();

				if(!compareComponent.$el.hasClass('active')) {
					_this.switchLayer(true);
				}
			},
			removeItem: function(id){
				var _this = compareComponent.slick,
					idx = $('.item[data-product-id="'+id+'"]').index(),
					$trigger = $('.js-compare[data-product-id="'+id+'"]');
				_this.$slideTarget.slick('slickRemove', idx);
				_this.setBlank();
				$trigger.removeClass('added').attr('title',$trigger.find('.add.sr-only').text());
				if(_this.$slideTarget.find('.product').length == 0) {
					_this.switchLayer(false);
				}
			},
			switchLayer: function(open){
				var _this = compareComponent.slick;
				if(open) {
					compareComponent.$el.addClass('active');
					setTimeout(function(){
						_this.$slideTarget.slick('setPosition');
						compareComponent.$el.addClass('showing');
					}, 50);
				}else {
					compareComponent.$el.removeClass('showing');
					compareComponent.$el.off('transitionend webkitTransitionEnd oTransitionEnd').on({
						'transitionend webkitTransitionEnd oTransitionEnd': function(){
							if(!compareComponent.$el.hasClass('showing')) {
								compareComponent.$el.removeClass('active');
							}
						}
					});
				}
			}
		},
		// add(or remove) to compare button
		trigger: {
			init: function(){
				$('body').off().on({
					click: compareComponent.switcher
				}, '.js-compare');

				var cookie = getCookie(compareComponent.cookie.name);
				if(cookie) {
					cookie = cookie.indexOf('|') >= 0 ? cookie.split('|') : [cookie];
					for (var i = 0; i < cookie.length; i++) {
						var _addedItem = document.querySelector('.js-compare[data-product-id="'+cookie[i]+'"]');
						$(_addedItem).addClass('added').attr('title',$(_addedItem).find('.remove.sr-only').text());
					}
				}
			}
		},
		cookie: null
	};

	compareComponent.init();
});
