$(document).ready(function() {
	if(!document.querySelector('.C0061')) return false;

	var $obj = $('.C0061');
	var $objIndex = $obj.attr('data-index','0');
	var ani ={
		event: function(obj) {
			var $icon = $obj.find('.slider-nav .icon a');
			
			$icon.hover(function(){
				$(this).parent().addClass('hover');
			}, function(){
				$(this).parent().removeClass('hover');
			});
			$icon.on('click', function(){
				var $this = $(this).parent();
				var $thisIndex = $this.index();
				$this.siblings().removeClass('active');
				$this.addClass('active');
				$(this).parents('.icon-area').next().find('.group').removeClass('active');
				$(this).parents('.icon-area').next().find('.group').eq($thisIndex).addClass('active');
				$(this).parents('.C0061').attr('data-index',$thisIndex);
				return false;
			});
		}
	};   	

	var $slickInit = function(){
		$obj.each(function(){
			var index = parseInt($(this).attr('data-index'));
			var _this = $(this);
			$(this).find('.slider-nav').slick({
				slidesToShow: 1,
				asNavFor: $(this).find('.slider-for'),
				dots: true,			
				arrows: false,
				focusOnSelect: true
			});
			$(this).find('.slider-for').slick({
				slidesToShow: 1,
				arrows: false,
				fade: true,
				asNavFor: $(this).find('.slider-nav')
			});
			$(this).find('.slider-nav').slick('slickGoTo',index);
			$(this).find('.slider-nav').on('afterChange', function(event, slick, currentSlide, nextSlide){
				_this.attr('data-index',currentSlide);
			});
		});  
		
	};
	var $unSlick = function(){
		if ($obj.find('.slider-nav, .slider-for').hasClass('slick-initialized')){
			var $slick = $obj.find('.slider-nav, .slider-for').slick('unslick');
			$obj.each(function(){
				var index = parseInt($(this).attr('data-index'));
				$(this).find('.slider-nav .icon').eq(index).find('a').trigger('click');
			});
		} else {
			return false;
		}
	};

	var screenSlick = function(e) {
		if (e.matches) {
			$slickInit();
		} else {
			$unSlick();
		}
	};

	ani.event();
	mql.maxSm.addListener(screenSlick);
	screenSlick(mql.maxSm);
});