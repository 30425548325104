$(document).ready(function() {
	if(!document.querySelector('.C0073')) return false;

	/*
	function calculateScrollFrame(){
		var _this =  calculateScrollFrame;
		
		_this.init = function(){
			var $tg = $(".tbl-box.desktop-display .comp-tbl"),
				cutover = 5,
				cellwidth = 257;
			
			_this.contentsLength( $tg, cutover, cellwidth, _this.itemLength( $tg.find("tr") ) );
		};
		
		_this.itemLength = function(item){
			var lengthMemo = [];
			for(var i=0; i<item.length; i++){
				lengthMemo[i] = item.eq(i).children().length;
			};
			return lengthMemo;
		};

		_this.contentsLength = function(tg, overline, itemWidth, arrayObj){
			var maxContentsLength = Math.max.apply(null, arrayObj);
			if ( maxContentsLength > overline ){
				tg.width( parseInt( maxContentsLength * itemWidth ) );
			};
		};

		_this.init();
	}
	calculateScrollFrame();
	*/
});



