$(document).ready(function() {
	if(!document.querySelector('.C0036')) return false;

	var $snsArea = $('.C0036 .sns-area');
	$snsArea.find('.sns').on('click', function(e){
		e.preventDefault();
		var $snsLayer = $(this).closest('.sns-area').find('.list');
		if($snsLayer.hasClass('active')) {
			$snsLayer.removeClass('active');
		} else {
			$snsLayer.addClass('active');
		}
	});
	
});
