$(document).ready(function() {
	if(!document.querySelector('.C0049')) return false;

	var $target = $('.C0049 .drag-area');
	$target.twentytwenty({
		no_overlay: true
	});
	$target.each(function(){
		var dragTitle = $(this).data('handle-title');
		$(this).find('.twentytwenty-handle').attr('title',dragTitle);
	});
});