$(document).ready(function() {
	// #### page : component FAQ
	if(!document.querySelector('.faq-accordion')) return false;
	
	var $obj = $('.faq-accordion');
	var $obj_header = $obj.find('.card-header');
	var $obj_body = $obj.find('.card-body');

	// accordion
	var accordion = function(){
		$obj_header.on('click', function(e){
			e.preventDefault();

			if( $('.C0040').hasClass('browseMobile') ){
				$(this).next('.card-body').collapse('toggle');
				$(this).toggleClass('active');
			} else {
				return false;
			}
		});
	};

	// browser size check
	var funcBrowseSize = function(e){
		if (e.matches) {
			$obj_header.removeClass('active');
			$obj_body.removeClass('show');
			
			$('.C0040').addClass('browseMobile');
		} else {
			$obj_header.addClass('active');
			$obj_body.addClass('show');

			$('.C0040').removeClass('browseMobile');
		}
	};

	mql.maxSm.addListener(funcBrowseSize);
	funcBrowseSize(mql.maxSm);
	
	accordion();
});