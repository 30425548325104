$(document).ready(function() {
	if(!document.querySelector('.C0010')) return false;

	var package = {
		el: document.querySelector('.C0010'),
		els: {
			totalPrice: null,
			selectedNumber: null
		},
		slick: {
			slideContainer: null,
			$slideTarget: null,
			// $slideAutoplayBtn: null,
			init: function(){
				var _slick = this;
				
				_slick.slideContainer = package.el;
				_slick.$slideTarget = $(_slick.slideContainer).find('.package-content');
				// _slick.$slideAutoplayBtn = $(_slick.slideContainer).find('.slick-indicator');

				_slick.$slideTarget.slick({
					infinite: false,
					slidesToShow: 3,
					slidesToScroll: 3,
					nextArrow: $(package.el).find('.package-next'),
					prevArrow: $(package.el).find('.package-prev'),
					dots:false,
					appendDots: $(package.el).find('.slick-dot-wrap'),
					responsive: [
						{
							breakpoint: 1284,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
						  breakpoint: 768,
						  settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							arrows: false,
							dots:true,
						  }
						}
					]
				});
			}
		},
		initialPrice: null,
		filtering: /[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,
		init: function(){
			var _this = this;

			_this.els.totalPrice = _this.el.querySelector('.total-price');
			_this.els.selectedNumber = _this.el.querySelector('.selected-number .number');

			_this.initialPrice = Number($(_this.el.querySelector('.total-price')).text()//;
			.replace(_this.filtering, ''));

			_this.slick.init();
			_this.addEvent();
		},
		sumPrice: function(e){
			var _this = package,
				_checkbox = e.currentTarget,
				_del = e.delegateTarget,
				sum = 0,
				currency = _this.els.totalPrice.getAttribute('data-currency');

			var _checked = _del.querySelectorAll('input[type="checkbox"]:checked');
			for (var i = 0; i < _checked.length; i++) {
				var val = _checked[i].value;
				val = Number(val.replace(_this.filtering, ''));
				sum += val;
			}
			sum = sum + _this.initialPrice;
			sum = sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // add commas

			$(_this.els.totalPrice).text(currency+sum);
			$(_this.els.selectedNumber).text(_checked.length);
		},
		addEvent: function(){
			var _this = this;
			$(_this.el).on({
				change: _this.sumPrice
			}, 'input[type="checkbox"]');
		}
	};

	package.init();
});
