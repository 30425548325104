$(document).ready(function() {
	if(!document.querySelector('.C0052')) return false;
	$('.C0052').each(function() {
		var anchor = $(this).find('.visual-set .visual-thumbnail-set a');
		anchor.on('click', function(e) {
			e.preventDefault();

			// change text
			var text = $(this).closest('.thumb-obj').find('.text-set-default').html();
			$(this).closest('.C0052').find('.text-set').html(text);

			// make large image tag
			var html = '';
			var largeImgURL = $(this).find('img').data('large');
			var largeArea = $(this).closest('.visual-set').find('.visual-area');

			if($(this).data('type') == 'youtube') {
				// youtube type
				videoID = $(this).data('video-id');
				videoTitle = $(this).data('video-title');
				alt = $(this).find('img').attr('alt');
				html = '<a href="#none" data-src="https://www.youtube.com/embed/'+videoID+'" class="see-video" data-type="youtube" data-target="modal"><img src="'+largeImgURL+'" alt="'+alt+'" /><div class="caption">'+videoTitle+'</div></a>';
				largeArea.addClass('video');
			} else if($(this).data('type') == 'brightcove') {
				// brightcove type
				videoID = $(this).data('video-id');
				account = $(this).data('account');
				player = $(this).data('player');
				videoTitle = $(this).data('video-title');
				alt = $(this).find('img').attr('alt');
				html = '<a href="#none" class="see-video" data-type="brightcove" data-video-id="'+videoID+'" data-account="'+account+'" data-player="'+player+'" data-target="modal"><img src="'+largeImgURL+'" alt="'+alt+'" /><div class="caption">'+videoTitle+'</div></a>';
				largeArea.addClass('video');
			} else {
				// image type
				alt = $(this).find('img').attr('alt');
				html = '<img src="'+largeImgURL+'" alt="'+alt+'" />';
				largeArea.removeClass('video');
			}
			largeArea.html(html);

			// change active class
			$(this).closest('.visual-thumbnail-set').find('.thumb-obj a').removeClass('active');
			$(this).addClass('active');
		});
	});
});