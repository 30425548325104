$(document).ready(function() {
	if(!document.querySelector('.C0009')) return false;

	var recently = {
		el: document.querySelector('.C0009'),
		id: null,
		init:function(){
			var _this = this;
			_this.id = _this.el.getAttribute('data-product-id');

			_this.cookie.bake();
		},
		cookie: {
			cookieName: 'LG5_RecentlyView',
			maximum: 12,
			dough: null,
			bake: function(){
				var _this = recently,
					_cookie = this;
				_cookie.dough = _cookie.get();

				if(!_cookie.dough){
					_cookie.dough = [_this.id];
				}else {
					if(typeof _cookie.dough == 'string') _cookie.dough = [_cookie.dough];

					var index = _cookie.dough.indexOf(_this.id);
					if(index == 0) {
						return false;
					}else if(index > 0){
						_cookie.dough.splice(index, 1);
					}
					_cookie.dough.unshift(_this.id);

					if(_cookie.dough.length > _cookie.maximum) {
						var del = _cookie.dough.length - _cookie.maximum;
						_cookie.dough.splice(_cookie.maximum, del);
					}
				}

				setCookie(_cookie.cookieName, _cookie.dough.join('|'), true);
			},
			get: function(){
				var _this = recently,
					_cookie = this,
					c = getCookie(_cookie.cookieName);

				if(c && c != '') {
					c = c.indexOf('|') >= 0 ? c.split('|') : c;
					return c;
				}else {
					return false;
				}
			},
		}
	}

	recently.init();
});
