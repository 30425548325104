$(document).ready(function() {
	if(!document.querySelector('.C0012')) return false;
	
	var $slick = function(){
		var $obj = $('.C0012');
		$obj.each(function(){
			var $objNext = $(this).find('.package-next');
			var $objPrev = $(this).find('.package-prev');
			$(this).find('.product-type .items').slick({
				autoplay: false,
				infinite: false,
				slidesToShow: 4,
				slidesToScroll: 4,
				dots: false,
				nextArrow:$objNext,
				prevArrow:$objPrev,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					}, {
						breakpoint: 768,
						settings: {
							dots: true,
							arrows: false,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
		});
	};
	$slick();
});
