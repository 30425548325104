$(document).ready(function() {
	if(!document.querySelector('.C0003')) return false;

	var $objAction = $('.C0003 .products-list-group2');
	$objAction.each(function() {
		var $slickAction = $(this).find('.items').slick({
			infinite: false,
			slidesToShow: 4,
			slidesToScroll: 4,
			arrows : true,
			dots: true,
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					arrows : true
				}
			}, {
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows : false
				}
			}],
			prevArrow: carouselOptions.bigAnglePrev, // common.js variable
			nextArrow: carouselOptions.bigAngleNext // common.js variable
		});
	});
});
