$(document).ready(function() {
	if(!document.querySelector('.C0009')) return false;

	var common = {
		breakpointMaxMd: mql.maxMd,
		breakpointMaxSm: mql.maxSm
	};

	var $snsArea = $('.pdp-summary-area .sns-area');
	$snsArea.find('.sns').on('click', function(e){
		e.preventDefault();
		var $snsLayer = $(this).closest('.sns-area').find('.list');
		if($snsLayer.hasClass('active')) {
			$snsLayer.removeClass('active');
		} else {
			$snsLayer.addClass('active');
		}
	});

	var modal = {
		ready: true,
		el: {
			gallery: document.getElementById('modal_pdp_detail_gallery')
		},
		iframe: {
			target: null,
			code: null,
			getYoutubeCode: function(t){
				var _this = t;
				var videoId = t.getAttribute('data-href');
				var videoTitle = t.getAttribute('data-title');
				var iframe = null,
					html = null;

				if(videoId.indexOf('watch?v=') > 0) {
					videoId = videoId.replace('watch?v=', '');
				}

				if(videoId.indexOf('/') > 0) {
					var i = videoId.split('/');
					videoId = i[i.length-1];
				}
				var playsinline = (t.getAttribute('data-target') != 'modal') ? '&playsinline=1' : '';
				iframe = '<iframe src="https://www.youtube.com/embed/'+videoId+'?&autoplay=1'+playsinline+'" allow="autoplay; encrypted-media" title="'+ videoTitle +'" allowfullscreen></iframe>';
				return iframe;
			},
			getBrightcoveCode: function(t){
				var videoId = t.getAttribute('data-video-id');
				var videoAccount = t.getAttribute('data-account');
				var videoPlayer = t.getAttribute('data-player');
				var videoTitle = t.getAttribute('data-title');
				var iframe = null,
					html = null;
				
				iframe = '<iframe src="https://players.brightcove.net/'+ videoAccount +'/'+ videoPlayer +'_default/index.html?videoId='+ videoId +'&autoplay=play" title="'+ videoTitle +'"  allowfullscreen="true" autoplay="play" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>';
				
				return iframe;
			},
			get360viewCode: function(t){
				var url = t.getAttribute('data-href'),
					title = t.getAttribute('data-title');
				var iframe;

				iframe = '<iframe src="'+url+'" title="'+title+'"></iframe>';
				return iframe;
			}
		},
		slick: {
			el : {
				nav: null,
				visual: null
			},
			opt : {
				nav: {
					lazyLoad: 'progressive',
					infinite: true,
					slidesToShow: 4,
					slidesToScroll: 1,
					asNavFor: '.slider-for',
					dots: true,
					focusOnSelect: true,
					vertical: false,
					dotsClass: 'pdp-pagination'
				},
				visual: {
					swipe: false,
					infinite: true,
					lazyLoad: 'progressive',
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					fade: true,
					asNavFor: '.slider-nav',
					responsive: [
						{
							breakpoint: 768,
							settings: {
								swipe: true
							}
						}
					]
				}
			},
			setIdx: function(){
				var _this = modal,
					_slick = modal.slick,
					_idx = _this.el.gallery.getAttribute('data-current-idx');

				if(_idx && typeof Number(_idx) == 'number') {
					$(_slick.el.visual)
						.slick('slickGoTo', _idx).slick('slickSetOption', {speed:300});
					$(_slick.el.nav).slick('slickGoTo', _idx);
				}
			},
			init: function(e){
				var _this = modal,
					_slick = modal.slick,
					init = _this.el.gallery.getAttribute('data-initialized');

				if(e.type == 'shown' && !init) {
					$(_slick.el.visual).on({
						init: function(){
							setTimeout(function(){
								$(_slick.el.visual).slick('slickSetOption', {speed:10});
								_slick.setIdx();
							}, 10);
						}
					});
					for( var key in _slick.el ) {
						var _curSlick = _slick.el[key];
						if(!$(_curSlick).is('.slick-initialized')){
							$(_curSlick).slick(_slick.opt[key]);
						}
					}
					_slick.filterBranch(common.breakpointMaxSm, [true]);
					_this.el.gallery.setAttribute('data-initialized', true);
				}else if(e.type == 'shown' && init){
					// setPosition inside modal Slicks
					setTimeout(function(){
						$(_slick.el.visual).slick('setPosition');
						$(_slick.el.nav).slick('setPosition');
					},10);

				}else if(e.type == 'show' && init) {
					_slick.setIdx();
				}

				_slick.addEvent();
			},
			filterBranch: function(e, firstCheck){
				var _this = modal,
					_slick = modal.slick;

				if(!firstCheck) {
					$(_this.el.gallery).modal('hide');
				}

				if (e.matches) { // mobile;
					if(!$(_slick.el.nav).is('.slick-initialized')){
						$(_slick.el.nav).slick(_slick.opt.nav);
					}
					$(_slick.el.nav).slick('slickFilter', ':not(.view)');
					$(_slick.el.visual).slick('slickFilter', ':not(.view)');

					var $navItem = $(_slick.el.nav).find('.item');
					var $visItem = $(_slick.el.visual).find('.item');

					for (var i = 0; i < $navItem.length; i++) {
						var idx = $navItem.eq(i).attr('data-slick-index');
						$navItem.eq(i).attr('data-slick-index', idx-1);
						$visItem.eq(i).attr('data-slick-index', idx-1);
					}
				} else {
					$(_slick.el.nav).slick('slickUnfilter').slick('unslick').find('.item').attr('tabindex', 0);
					$(_slick.el.visual).slick('slickUnfilter');
					// var $navItem = $(_slick.el.nav);
					// var $visItem = $(_slick.el.visual);

					// for (var i = 0; i < $navItem.length; i++) {
					// 	$navItem.eq(i).attr('data-slick-index', i);
					// 	$visItem.eq(i).attr('data-slick-index', i);
					// }
				}
			},
			addEvent: function(){
				var _this = modal,
					_slick = modal.slick;
				$(_slick.el.visual).off().on({
					init: function(event, slick){
						//console.log(slick.$slides);
						// setTimeout(function(){
							$(_slick.el.visual).trigger('beforeChange', [slick, 0, 0]);
							$(_slick.el.visual).trigger('afterChange', [slick, 0]);
						// },100);
					},
					afterChange: function(event, slick, currentSlide){
						var $this = slick.$slides.eq(currentSlide);
						slick.$slides.eq(currentSlide).find('a').focus();

						var idx = currentSlide;
						$(_this.el.gallery.querySelector('.pdp-visual-pagination .current')).text(currentSlide+1);

						// iframe item check;
						var _iframe = $this.find('.iframe-area')[0];
						if(_iframe){
							var i = $(_iframe).data(),
								html;

							if(i.type == '360') {
								html = modal.iframe.get360viewCode(_iframe);
							}else if(i.type == 'youtube'){
								html = modal.iframe.getYoutubeCode(_iframe);
							}else if(i.type == 'brightcove'){
								html = modal.iframe.getBrightcoveCode(_iframe);
							}
							$(_iframe).html(html);
						}
					},
					beforeChange: function(event, slick, currentSlide, nextSlide){
						var $this = slick.$slides.eq(currentSlide),
							$next = slick.$slides.eq(nextSlide);

						var _thisIframe = $this.find('.iframe-area')[0];
						if(_thisIframe) {
							$(_thisIframe).html('');
						}

						var _iframe = $next.find('.iframe-area')[0];
						if(_iframe){
							$(_this.el.gallery).addClass('loaded-iframe');
						}else {
							$(_this.el.gallery).removeClass('loaded-iframe');
						}
					}
				});
				$(_slick.el.nav).off().on({
					beforeChange: function(event, slick, currentSlide, nextSlide){
						console.log(event, currentSlide, nextSlide);
					}
				});

				$(_this.el.gallery).off('click.nav').on({
					'click.nav': function(e){
						var idx = $(e.currentTarget).index();
						$(_slick.el.visual)
							.slick('slickGoTo', idx).slick('slickSetOption', {speed:300});
						$(e.currentTarget).addClass('slick-current slick-active').siblings().removeClass('slick-current slick-active');
					}
				}, '.slider-nav:not(.slick-initialized) .item');
				common.breakpointMaxSm.addListener(_slick.filterBranch);
			}
		},
		zoom: {
			init: function(){
				var _this = modal;
				var _zoom = this;
				var zoomImg = _this.el.gallery.querySelectorAll('.zoom-area img');
				var _opt = {
					mobile : {
						cursor: 'inherit',
						transition: !0,
						panOnlyWhenZoomed: !0,
						linearZoom: !0,
						exponential: !1,
						contain: !1,
						minScale: 1,
						animate: !0,
						maxScale: 4,
						increment: 0.4,
						onPan: function(){
							$(this).addClass('dragged');
						},
						onZoom: function(){
							var $n = $(this);
							var matrix = $n.panzoom("getMatrix");
							if(matrix[0] <= "1.1") {
								if(matrix[4] != 0 && matrix[5] != 0) {
									$n.panzoom("reset");
								}
							}
						}
					},
					pc: {
						cursor: 'inherit',
						transition: !0,
						panOnlyWhenZoomed: !0,
						linearZoom: !0,
						exponential: !1,
						contain: !1,
						minScale: 1,
						animate: !0,
						maxScale: 2,
						increment: 0.2,
						onPan: function(){
							$(this).addClass('dragged');
						},
						onZoom: function(){
							var $n = $(this);
							var matrix = $n.panzoom("getMatrix");
							if(matrix[0] <= "1.1") {
								if(matrix[4] != 0 && matrix[5] != 0){
									$n.panzoom("reset");
								}
							}
						}
					}
				};

				if(!_this.el.gallery.getAttribute('data-zoom-initialized')) {
					_this.el.gallery.setAttribute('data-zoom-initialized', true);


					for (var i = 0; i < zoomImg.length; i++) {
						var __this = zoomImg[i],
							opt;
						if($(__this).is('.pc')) {
							opt = _opt.pc;
						}else {
							opt = _opt.pc;
						}
						$(__this).panzoom(opt);
					}
					
				}

				if(!_this.el.gallery.getAttribute('data-zoom-event')){
					_this.el.gallery.setAttribute('data-zoom-event', true);
					_zoom.addEvent();
				}
				
			},
			in: function($target, opt, max){
				$target.panzoom("zoom", !1, {
					increment: max ? opt.maxScale : opt.increment,
					animate: true
				});
				if(opt.maxScale == $target.panzoom("getMatrix")[0]){
					$target.parent('.zoom-area').addClass('zoomout');
				}else {
					$target.parent('.zoom-area').removeClass('zoomout');
				}
			},
			out: function($target, opt){
				if($target.panzoom("getMatrix")[0] <= (1+opt.increment)) {
					$target.panzoom("reset");
				}else {
					$target.panzoom("zoom", !0, {
						increment: opt.increment,
						animate: true
					});
				}
				$target.parent('.zoom-area').removeClass('zoomout');
			},
			clickAction: function(e){
				var _this = modal,
					_zoom = _this.zoom,
					currentTarget = e.currentTarget ? e.currentTarget : e.target,
					$target = $(currentTarget),
					opt;

				opt = $target.panzoom("option");

				if(!$target.is('.dragged')) {
					var $area = $target.parent('.zoom-area');
					if(!$area.is('.zoomout')) {
						if(e.type == 'doubletap') {
							_zoom.in($target, opt, true); // maximum zoomin
						}else {
							_zoom.in($target, opt);
						}
					}else {
						$target.panzoom('reset');
						$area.removeClass('zoomout');
					}
				}

				$target.removeClass('dragged');
			},
			addEvent: function(){
				var _this = modal,
					_zoom = this;
				$(_this.el.gallery).on({
					'click.panzoom': function(e){
						var $target = $(_this.el.gallery).find('.slick-active .zoom-area img');
						var opt;
						var $btn = $(e.currentTarget);

						if(common.breakpointMaxSm.matches) {
							$target = $target.filter('.mobile');
						}else {
							$target = $target.filter('.pc');
						}

						opt = $target.panzoom("option");

						if($btn.is('.plus')){
							_zoom.in($target, opt);
						}else if($btn.is('.minus')){
							_zoom.out($target, opt);
						}
					}
				}, 'button');

				if ('ontouchstart' in window){
					// in touch device
					var $target = $(_this.el.gallery).find('.zoom-area img');
					if(common.breakpointMaxSm.matches) {
						$target = $target.filter('.mobile');
					}else {
						$target = $target.filter('.pc');
					}

					for (var i = 0; i < $target.length; i++) {
						var _target = $target.eq(i)[0];
						new Hammer(_target).on('doubletap', _zoom.clickAction);
					}
				}else {
					$(_this.el.gallery).on({
						click: _zoom.clickAction
					}, '.zoom-area img');
				}
			}
		},
		init: function(){
			var _this = this,
				_slick = _this.slick;

			_slick.el.nav = _this.el.gallery.querySelector('.slider-nav');
			_slick.el.visual = _this.el.gallery.querySelector('.slider-for');

			_this.addEvent();
		},
		showDetailModal: function(idx){
			var _this = this;
			_this.el.gallery.setAttribute('data-current-idx', idx);
			if($(modal.slick.el.visual).is('.slick-initialized')) {
				$(modal.slick.el.visual).trigger('afterChange', [$(modal.slick.el.visual).slick('getSlick'), idx]);
			}
			$(modal.slick.el.nav).find('.item').eq(idx).addClass('slick-active slick-current').siblings().removeClass('slick-active slick-current');
			$(_this.el.gallery).modal('show');

			_this.zoom.init();
		},
		addEvent: function(){
			var _this = this,
				_slick = _this.slick;
			$(_this.el.gallery).on({
				'shown.bs.modal': _slick.init,
				'show.bs.modal': _slick.init,
				'hidden.bs.modal': function(){
					$(this).find('iframe').remove();
				}
			});
			// $(_this.el.iframe).on({
			// 	'hidden.bs.modal': function(){
			// 		$(this).find('iframe').remove();
			// 	}
			// });
		}
	};

	var summaryGallery = {
		breakpoint: mql.maxMd, // ~ 991
		d: {
			el: document.getElementById('desktop_summary_gallery'),
			els: {
				nav: null,
				visual: null
			},
			init: function(){
				var _d = this;
				_d.els.visual = _d.el.querySelector('.pdp-visual-image');
				_d.els.nav = _d.el.querySelector('.pdp-thumbnail-nav');

				_d.setThumbnailsIdx();
				_d.addEvent();
				_d.el.setAttribute('data-initialized', true);
			},
			setThumbnailsIdx: function(){
				var _d = this;
				var lis = _d.els.nav.querySelectorAll('li');
				for (var i = 0; i < lis.length; i++) {
					var _li = lis[i];
					_li.setAttribute('data-idx', i);
				}

				var defaultIdx = $(_d.els.nav.querySelector('.thumbnail')).index();
				_d.els.visual.querySelector('img').setAttribute('data-idx', defaultIdx);
			},
			setImage: function(e){
				var _d = this,
					_img = _d.els.visual.querySelector('img'),
					img = e.target,
					anchor = e.currentTarget,
					del = $(anchor).closest('li')[0],
					src = img.getAttribute('data-medium'),
					alt = img.getAttribute('alt');

				_img.setAttribute('data-idx', del.getAttribute('data-idx'));
				_img.setAttribute('src', src);
				_img.setAttribute('alt', alt);

				$(del).addClass('active')
					.siblings().removeClass('active');
			},
			addEvent: function(){
				var _d = this;
				$(_d.els.nav).on({
					click: function(e){
						e.preventDefault();
						var del = $(this).closest('li')[0];
						if($(del).is('.thumbnail') && !$(del).is('.more')){ // thumbnail button
							_d.setImage(e);
						}else if($(del).is('.more') || $(del).is('.iframe')){ // more button
							var idx = del.getAttribute('data-idx');
							modal.showDetailModal(idx ? idx : 0);
						}
					}
				}, 'a');
				$(_d.els.visual).on({
					click: function(e){
						var idx = e.target.getAttribute('data-idx');
						modal.showDetailModal(idx ? idx : 0);
					}
				}, 'a');
			}
		},
		m: {
			el: document.getElementById('mobile_summary_gallery'),
			slick: {
				el: null,
				page: null,
				opt: {
					infinite:true,
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows : true,
					dots: false,
					prevArrow: carouselOptions.bigAnglePrev, // common.js variable
					nextArrow: carouselOptions.bigAngleNext, // common.js variable
					responsive: [
						{
							breakpoint: 768,
							settings: {
								arrows: false
							}
						}
					],
				}
			},
			init: function(){
				var _m = this;
				_m.slick.el = _m.el.querySelector('.pdp-carousel');
				_m.slick.page = _m.el.querySelector('.pdp-visual-pagination');

				_m.setThumbnailsIdx();

				// pagination max setting
				$(_m.slick.page.querySelector('.current')).text(1);
				$(_m.slick.page.querySelector('.length')).text(_m.slick.el.querySelectorAll('.default').length);

				$(_m.slick.el).slick(_m.slick.opt);

				_m.addEvent();
				_m.el.setAttribute('data-initialized', true);
			},
			setThumbnailsIdx: function(){
				var _m = this;
				var lis = _m.slick.el.querySelectorAll('.default');
				for (var i = 0; i < lis.length; i++) {
					var _li = lis[i];
					_li.setAttribute('data-idx', i);
				}
			},
			addEvent: function(){
				var _m = this;
				
				$(_m.slick.el).on({
					beforeChange: function(event, slick, currentSlide, nextSlide){
						modal.ready = false;
					},
					afterChange: function(event, slick, currentSlide){
						modal.ready = true;
						slick.$slides.eq(currentSlide).find('a').focus();

						var idx = currentSlide;
						$(_m.slick.page.querySelector('.current')).text(currentSlide+1);
					}
				});

				$(_m.slick.el.querySelectorAll('.default')).on({
					click: function(e){
						var _del = e.delegateTarget,
							idx = e.delegateTarget.getAttribute('data-idx');
						if(modal.ready == true) {
							modal.showDetailModal(idx ? idx : 0);
						}
					}
				}, 'a');
			}
		},
		init: function(){
			var _this = this;

			_this.addEvent();
			_this.mediaBranch(common.breakpointMaxMd);
		},
		mediaBranch: function(e, rebuild) {
			var _this = summaryGallery;

			if (e.matches) { // mobile;
				_this = _this.m;
			} else { 
				_this = _this.d;
			}

			if(_this.el.getAttribute('data-initialized') != 'true') {
				_this.init();
			}
		},
		addEvent: function(){
			var _this = this;
			common.breakpointMaxMd.addListener(_this.mediaBranch);
		}
	};

	// right pdp Summary 
	var detach = {
		id: null,
		els: {}
	};
	var info = {
		// group: typeof groupModelInfo != 'undefined'?groupModelInfo:null,
		// price: typeof priceModelInfo != 'undefined'?priceModelInfo:null
		group: null,
		price: null
	};
	var summary = {
		el: document.querySelector('.pdp-summary-area'),
		forms: document.querySelectorAll('.search-common'),
		els: {
			modal: {
				thumbnails: {
					target: document.getElementById('modal_thumbnail_target'),
					template: document.getElementById('modal_thumbnail_template'),
				},
				detail: {
					target: document.getElementById('modal_detail_target'),
					template: document.getElementById('modal_detail_template'),
				}
			},
			base: {
				mobile: {
					target: document.getElementById('base_mobile_target'),
					template: document.getElementById('base_mobile_template'),
				},// thumbnail
				desktop: {
					target: document.getElementById('base_desktop_target'),
					template: document.getElementById('base_desktop_template'),
				},
				detail: {
					target: document.getElementById('base_detail_target'),
				}
			}
		},
		create: {
			temp: null,
			desktopThumbnail: function(imgList){
				var _this = summary,
					_els = _this.els,
					// template = $(_els.base.desktop.template).clone().html(),
					// $desktopThumbnail = $(_els.base.desktop.target).find('.thumbnail img');
					$desktopThumbnail = $(_els.base.desktop.target).find('li:not(.view)'),
					length = 6,
					current, template,
					html = [];

					if(_this.create.temp == null) {
						_this.create.temp = $(_els.base.desktop.template).clone().html();
					}
					template = _this.create.temp;
					

					$desktopThumbnail.remove();

				for (var i = 0; i < length; i++) {
					var curData = imgList[i];
					// if(i < $desktopThumbnail.length) {
					// 	var $current = $desktopThumbnail.eq(i);
					// 	$current[0].setAttribute('src', curData.smallImageAddr);
					// 	$current[0].setAttribute('data-medium', curData.largeImageAddr);
					// 	$current[0].setAttribute('alt', curData.alt);

					// 	if(i == $desktopThumbnail.length-1) {
					// 		var moreLength = imgList.length - $desktopThumbnail.length;
					// 		$current.siblings('.count').text("+"+moreLength);
					// 	}
					// }

					if(curData.videoId){
						current = $(template).filter('.video').clone()[0];
						current.querySelector('img').setAttribute('src', curData.videoThumbnail);
						current.querySelector('img').setAttribute('alt', curData.videoDesc);
					}else if(i == (length-1)) {
						current = $(template).filter('.more').clone()[0];
						current.querySelector('img').setAttribute('src', curData.smallImageAddr);
						current.querySelector('img').setAttribute('data-medium', curData.largeImageAddr);
						var moreLength = imgList.length - length;
							$(current.querySelector('.count')).text("+"+moreLength);
					}else {
						current = $(template).filter('.thumbnail:not(.more)').clone()[0];
						current.querySelector('img').setAttribute('src', curData.smallImageAddr);
						current.querySelector('img').setAttribute('data-medium', curData.largeImageAddr);
					}
					current.setAttribute('data-idx', i+1);
					html[i] = current;
				}
				$(_els.base.desktop.target.querySelector('ul')).append(html);
			},
			elements: function(imgList, el, key){
				var _this = summary;
				var template = $(el.template).html(),
					html = [];

				for (var i = 0; i < imgList.length; i++) {
					var curData = imgList[i],
						condition = false,
						current, img;

					if(curData.videoId) {
						if($(template).filter('.video').length > 1) {
							current = $(template).filter('.'+curData.videoType).clone()[0];
							if(curData.videoType == 'youtube') {
								current.querySelector('.iframe-area').setAttribute('data-href', 'https://www.youtube.com/watch?v='+curData.videoId);
							}else {
								current.querySelector('.iframe-area').setAttribute('data-video-id', curData.videoId);
							}
							current.querySelector('.iframe-area').setAttribute('data-title', curData.videoDesc);
							current.querySelector('.iframe-area').setAttribute('data-type', curData.videoType);
							condition = true;
						}else {
							current = $(template).filter('.video').clone()[0];
						}
					}else {
						current = $(template).filter('.image').clone()[0];
					}

					if(!condition) {
						img = current.querySelectorAll('img');
						if(img.length > 1) { // is zoom image
							$(img).filter('.pc')[0].setAttribute('data-lazy', curData.zoomImageAddr);
							$(img).filter('.mobile')[0].setAttribute('data-lazy', curData.zoomMobImageAddr);
						}else { // is thumbnail image
							img = img[0];
							if(key) {
								img.setAttribute('data-lazy', curData[key]);
							}else {
								img.setAttribute('data-lazy', curData.smallImageAddr);
							}
						}
						
						$(img).attr('alt', curData.alt);
					}
					// 	current = $(template).clone()[0],
					
					html[i] = current;
				}

				return html;
			}
		},
		init: function(){
			var _this = this;
			_this.addEvent();

			var href = location.search ? location.search : location.hash,
				param = href.indexOf('=') > -1 ? href.split('=')[1] : href;
			param = param.indexOf('#') > -1 ? param.split('#')[0] : param;
			console.log(param);
			if(param) {
				$('a[data-sub-model-id="'+param+'"]').eq(0).trigger('click');
			}
		},
		changeSubModelInfo: function(id, subid){
			var _this = this,
				url = _this.el.getAttribute('data-sibling-url'),
				templateUrl = _this.el.getAttribute('data-template-url'),
				param = {
					modelId: id,
					subModelId: subid
				};

			$(_this.els.base.desktop.target).find('.active').removeClass('active');

			ajax.call(url, param, 'json', function(data){
				if(data.status == 'success' && data.data){
					var p = data.data[0];
					ajax.call(templateUrl, {}, 'html', function(html){
						if(html) {
							var pdpUrl = location.href;
							// simple data
							html = html
								.replace(/\*pdpUrl\*/g, pdpUrl)
								.replace(/\*userFriendlyName\*/g, p.userFriendlyName)
								.replace(/\*mediumImageAddr\*/g, p.mediumImageAddr)
								.replace(/\*productTag1\*/g, p.productTag1)
								.replace(/\*productTag2\*/g, p.productTag2)
								.replace(/\*modelDisplayName\*/g, p.modelDisplayName)
								.replace(/\*modelId\*/g, p.modelId)
								.replace(/\*addToCartUrl\*/g, p.addToCartUrl)
								.replace(/\*whereToBuyUrl\*/g, p.whereToBuyUrl)
								.replace(/\*inquiryToBuyUrl\*/g, p.inquiryToBuyUrl)
								.replace(/\*findTheDealerUrl\*/g, p.findTheDealerUrl)
								.replace(/\*deliveryMessage\*/g, p.deliveryMessage)
								.replace(/\*installMessage\*/g, p.installMessage)
								.replace(/\*warrantyMessage\*/g, p.warrantyMessage)
								.replace(/\*defaultMessage\*/g, p.defaultMessage)
								// compatible
								.replace(/\*compatibleCount\*/g, p.compatibleCount)
								.replace(/\*compatibleModelsValidateUrl\*/g, p.compatibleModelsValidateUrl)
								.replace(/\*compatibleModelsPredictiveUrl\*/g, p.compatibleModelsPredictiveUrl)
								// price
								.replace(/\*rPrice\*/g, p.rPrice ? p.rPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'null')
								// .replace(/\*msrp\*/g, p.msrp ? p.msrp.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'null')
								.replace(/\*rPromoPrice\*/g, p.rPromoPrice ? p.rPromoPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'null')
								.replace(/\*rPriceCent\*/g, p.rPriceCent)
								.replace(/\*rPromoPriceCent\*/g, p.rPromoPriceCent)
								.replace(/\*discountedRate\*/g, p.discountedRate)
								.replace(/\*promotionText\*/g, p.promotionText);

							var $html = $(html),
								$keyBlocks = $html.find('*[data-key]'),
								$loopBlocks = $html.find('*[data-loop]');
							
							for (var i = 0; i < $keyBlocks.length; i++) {
								var $currentKeyBlock = $keyBlocks.eq(i),
									key = $currentKeyBlock.get(0).getAttribute('data-key'),
									val = p[key];
								if(!val || val == "N") {
									$currentKeyBlock.remove();
								}
							}

							if(p.bizType.toUpperCase() == "B2B") $html.find('.rating-area').remove();

							// price setting
							var $prices = $html.find('.price-area');
							for (var i2 = 0; i2 < $prices.length; i2++) {
								var $this = $prices.eq(i2),
									$price;
								if(p.promoPriceUseFlag == "Y") {
									$price = $this.find('.rPromoPrice').clone();
								}else if(p.bizType.toUpperCase() =='B2B' && p.msrpUseFlag=='Y') {
									$price = $this.find('.msrp').clone();
								}else {
									$price = $this.find('.rPrice').clone();
								}
								$this.html($price.html());
							}

							// compatibleCount type setting
							if(p.compatibleCount > 6) {
								// search type
								$html.find('.compatible-list').remove();
							}else {
								// list type
								$html.find('.search-model').remove();
							}
							
							// loop contents setting
							for (var i3 = 0; i3 < $loopBlocks.length; i3++) {
								var $currentLoopBlock = $loopBlocks.eq(i3),
									_key = $currentLoopBlock.get(0).getAttribute('data-loop'),
									_val = p[_key];

								if(_key == 'siblings') {
									for (var j1 = 0; j1 < _val.length; j1++) {
										var siblingGroup = _val[j1],
											siblingTemplate, itemTemplate,
											siblingHtml = [],
											itemHtml = [];

										if(siblingGroup.siblingType.toUpperCase() == "COLOR") {
											siblingTemplate = $currentLoopBlock.find('.template-sibling-color').clone().html();
										}else {
											siblingTemplate = $currentLoopBlock.find('.template-sibling-size').clone().html();
										}

										if(siblingGroup.target.toUpperCase() == "NEW"){
											itemTemplate = $(siblingTemplate).find('.new').clone().html();
										}else {
											itemTemplate = $(siblingTemplate).find('.self').clone().html();
										}

										for (var k = 0; k < siblingGroup.siblingModels.length; k++) {
											var model = siblingGroup.siblingModels[k];
											itemHtml = itemTemplate;
											itemHtml = itemHtml.replace(/\*subModelId\*/g, model.modelId)
															.replace(/\*siblingValue\*/g, model.siblingValue)
															.replace(/\*subModelUrl\*/g, model.modelUrl)
															.replace(/\*siblingCode\*/g, model.siblingCode);
											if(p.modelId == model.modelId) {
												itemHtml = $(itemHtml).addClass('active')[0].outerHTML;
											}
											siblingHtml += itemHtml;
										}

										var $temp = $(siblingTemplate);
										$temp.find('div').html(siblingHtml);
										$currentLoopBlock.append($temp);
									}
								}else {
									var template = $currentLoopBlock.find('template').clone().html(),
										appendHtml = [];

									for (var j2 = 0; j2 < _val.length; j2++) {
										// appendHtml += template.replace(/\*compModelSku\*/g, _val[j2].compModelSku);
										// var str = "*"+_val[j2]+"*";
										var __val = _val[j2],
											htmlLine = template;
										for(var __key in __val) {
											if(template.indexOf(__key) > -1) {
												htmlLine = htmlLine.replace("*"+__key+"*", __val[__key]);
											}
										}
										appendHtml += htmlLine;
									}
									$currentLoopBlock.html(appendHtml);
								}
							}

							// console.log($html.filter(''));
							$(_this.el).find('.mobile-top-info').html($html.filter('.mobile-top-info').html());
							$(_this.el).find('.mobile-bottom-info').html($html.filter('.mobile-bottom-info').html());
							$(_this.el).find('.desktop-info').html($html.filter('.desktop-info').html());

							// change gallery image (modelId, baseProductimage, baseProductAlttext, galleryImgList)
							var galleryList = p.videos.concat(p.galleryImages);
							console.log(galleryList);
							// _this.changeSubModelImage(id, p.galleryImages[0].largeImageAddr, p.imageAltText, p.galleryImages);
							_this.changeSubModelImage(id, p.galleryImages[0].largeImageAddr, p.imageAltText, galleryList);

							searchInit(); // common.js
							scrollDesign(); // mCustomScrollbar.js
						}
					});
				}
			});
		},
		changeSubModelImage: function(id, basicImg, basicAlt, imgList){
			var _this = this,
				_els = _this.els;
				// basicImg = info.group.subModelInfo[id].basicImg,
				// imgList = info.group.subModelInfo[id].galleryImg;
			_els.base.detail.target.setAttribute('src', basicImg);
			_els.base.detail.target.setAttribute('alt', basicAlt);
			_els.base.detail.target.setAttribute('data-src', basicImg);

			// change desktop thumbnails
			_this.create.desktopThumbnail(imgList);

			// reset slicks
			summaryGallery.m.el.removeAttribute('data-initialized');
			modal.el.gallery.removeAttribute('data-initialized');
			var slicks = [
				$(_els.base.mobile.target),
				$(_els.modal.thumbnails.target),
				$(_els.modal.detail.target)
			];
			for (var i = 0; i < slicks.length; i++) {
				var _curSlick = slicks[i];
				if(_curSlick.is('.slick-initialized')) {
					_curSlick.slick('unslick');
				}
			}

			$(_els.base.mobile.target).find('.thumbnail, .video').remove();
			$(_els.modal.thumbnails.target).find('.image, .video').remove();
			$(_els.modal.detail.target).find('.image, .video').remove();

			var mobileThumbnail = _this.create.elements(imgList, _els.base.mobile, "largeImageAddr"),
				modalThumbnail = _this.create.elements(imgList, _els.modal.thumbnails, "smallImageAddr"),
				modalDetail = _this.create.elements(imgList, _els.modal.detail);

			$(_els.base.mobile.target).append(mobileThumbnail);
			$(_els.modal.thumbnails.target).append(modalThumbnail);
			$(_els.modal.detail.target).append(modalDetail);

			if(modal.el.gallery.getAttribute('data-zoom-initialized')) {
				modal.el.gallery.removeAttribute('data-zoom-initialized');
			}
			summaryGallery.init();

		},
		addEvent: function(){
			var _this = this;
			$(_this.el).on({
				click: function(e){
					var _anchor = e.currentTarget,
						id = _anchor.getAttribute('data-model-id'),
						subId = _anchor.getAttribute('data-sub-model-id');

					// if($(_anchor).siblings('.active')[0]){
					// 	detach.id = $(_anchor).siblings('.active')[0].getAttribute('data-sub-model-id');

						if(subId){
							e.preventDefault();
							// $("a[data-sub-model-id='"+subId+"']").addClass('active').siblings().removeClass('active');
							var state = {'submodelId':subId};
							if(window.history.pushState) {
								window.history.pushState({'submodelId':subId}, null, '?submodelId='+subId);
							}else {
								location.hash = 'submodelId='+subId;
								location.search = '';
							}

							_this.changeSubModelInfo(id, subId);
						}
					// }
				}
			}, '.sibling-area a');

			$(_this.el).on({
				change: function(e){
					var cur = e.currentTarget,
						$block = $(cur).closest('form').find('.field-block');
					if(cur.value.length == 0) {
						$block.removeClass('error pass fail');
					}
					$block.find('.search-common-input').val(cur.value);
				}
			}, '.search-common .search-common-input');
			$(_this.el).on({
				click: function(e){
					e.preventDefault();
					var cur = e.currentTarget,
						$input = $(cur).closest('form').find('.search-common-input'),
						model = $(cur).find('.model-name').text();
					$(cur).closest('form').find('.search-layer').removeClass('active');
					$input.val(model).focus();

					$(cur).closest('form').trigger('submit');
				}
			}, '.search-common a.anchor');
			$(_this.el).on({
				click: function(e){
					e.preventDefault();
					var cur = e.currentTarget;
					var $input = $(cur).closest('form').find('.search-common-input');

					$input.val('').focus().trigger('change');
				}
			}, '.search-common input.reset');
			$(_this.el).on({
				submit: function(e){
					e.preventDefault();

					var _del = $(e.currentTarget).closest('form'),
						$block = $(_del).find('.field-block'),
						url = $(_del).attr('action'),
						param = xssfilter($(_del).serialize());

					$(_del).find('.search-layer').removeClass('active');

					if($block.find('.search-common-input').val() != '') {
						ajax.call(url, param, 'json', function(data){
							if(data.results == true) {
								// success
								$block.addClass('pass').removeClass('error fail');
								$block.find('.valid').show().siblings().hide();
							}else {
								// fail
								$block.removeClass('pass error').addClass('fail');
								$block.find('.invalid').show().siblings().hide();
							}
						});
					}else {
						$block.removeClass('pass fail').addClass('error');
						$block.find('.required').show().siblings().hide();
					}
				}
			}, '.search-common');
		}
	};

	summary.init();

	modal.init();
	summaryGallery.init();
});
