$(document).ready(function() {
	if(!document.querySelector('.pdp-layer-tab-object')) return false;
	
	//change text
	var txtClose = 'close';
	$('.btn-tech-ctrl').click(function(){
		var $specArea = $(this).parent().next('.tech-spacs-area');
		$specArea.collapse('toggle');
		
		return false;
	});

	$('.tech-spacs-wrap').on({
		'show.bs.collapse': function(e){
			var btn = e.delegateTarget.querySelector('.btn-tech-ctrl');
			$(btn).removeClass('collapsed');
			$(btn).text(btn.getAttribute('data-close-title'));
		},
		'hide.bs.collapse': function(e){
			var btn = e.delegateTarget.querySelector('.btn-tech-ctrl');
			$(btn).addClass('collapsed');
			$(btn).text(btn.getAttribute('data-open-title'));
		}
	}, '.tech-spacs-area');
	
	var specRunPrint;
	specRunPrint= function(){
		if(!document.querySelector('.page-print') && !document.querySelector('.page-print')) return false;
		var $printPage = $('.page-print');
		$printPage.off().on('click', function(e) {
			e.preventDefault();
			var modal = $(e.currentTarget).parents('.component-wrap').parent().get(0);
			if(modal) {
				//console.log('1');
				var divToPrint=modal;
				var newWin=window.open('','Print-Window');
				newWin.document.open();
				newWin.document.write('<html><body><link href="/lg5-common/css/fonts.min.css" type="text/css" rel="stylesheet"><link href="/lg5-common/css/default.min.css" type="text/css" rel="stylesheet"><link rel="stylesheet" href="/lg5-common/css/components.min.css" type="text/css" /><link rel="stylesheet" href="/lg5-common/css/modal-print.min.css" type="text/css" /><div class="specs-print">'+divToPrint.innerHTML+'</div><script src="/lg5-common/library/jquery.min.js" ></script><script>if($("img.lazyload").length>0) {$("img.lazyload").each(function() {$(this).attr("src", $(this).data("src")).removeClass("lazyload").addClass("lazyloaded");});}setTimeout(function() {window.print();}, 100);</script></body></html>');
				newWin.document.close();
				setTimeout(function(){newWin.close();},10);
			}else {
				window.print();
			}
		});
	};
	specRunPrint();
});