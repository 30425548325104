$(document).ready(function() {
	if( !document.querySelector('.C0011') ) return false;

	var timer;
	function tab_sticky(){
		var p = tab_sticky.prototype;

		// DOM variable
		var $this = $(".C0011"),
			$btnDropMenu = $this.find(".btn-drop-menu-mob"),
			$tabMenuList = $this.find(".tab-menu-belt"),
			$dimedLayer = $this.find(".back-layer"),
			$linkers = $tabMenuList.find('.linker');

		// sticky by page scroll
		var	cutLine = parseInt( $this.closest(".component-wrap").offset().top );

		$(window).on("resize", function(){
			cutLine = parseInt( $this.closest(".component-wrap").offset().top );
			return cutLine;
		});
		

		$tabMenuList.find('.linker').on('click', function(e){
			e.preventDefault();
			var target = $(this).attr('href');
			var objTop = $(target).offset().top - $this.outerHeight();
			var hasPadding = ["#pdp_review", "#pdp_spec"];
			if(hasPadding.indexOf(target) >= 0) {
				objTop = objTop - 50;
			}

			// $(e.currentTarget).parent('.tab-menu').addClass('active').siblings().removeClass('active');
			$('html, body').stop().animate({scrollTop:objTop}, {duration:500});
		});

		p.init = function(){
			mql.maxSm.addListener(p.resizeReset);
			this.resizeReset(mql.maxSm);
			this.eventOn();
		};

		p.scrollEvent = function(){
			cutLine = parseInt( $this.closest(".component-wrap").offset().top );
			
			var scrollPos =  $(window).scrollTop();
			var stiky_condition = scrollPos >= parseInt(cutLine);

			p.scrollPosCheck(stiky_condition);
			p.scrollAutoFocusing(scrollPos);

			// drop reset
			$tabMenuList.removeClass("open");
			$btnDropMenu.removeClass("open");
			$dimedLayer.removeClass("active");
		};

		p.eventOn = function(){
			// p.scrollEvent();
			$(window).scroll(function(){
				// clearTimeout( timer );
				// timer = setTimeout( p.scrollEvent, 100 );
				p.scrollEvent();
			});
			
			// mobile event
			$btnDropMenu.on("touch click", function(){
				p.dropMenu( $tabMenuList.hasClass("open") );
				p.dimedException( $this.attr("class").indexOf("sticky-active") < 0 );

			});

			$dimedLayer.on("touch click", function(){
				p.dropMenu( $tabMenuList.hasClass("open") );
			});
		};

		p.scrollPosCheck = function(condition){
			if(condition) {
				$this.addClass("sticky-active");
			} else {
				$this.removeClass("sticky-active");
			}
		};

		p.dropMenu = function(condition){
			if(condition) {
				$tabMenuList.removeClass("open");
				$btnDropMenu.removeClass("open");
				$dimedLayer.removeClass("active");
			} else {
				$tabMenuList.addClass("open");
				$btnDropMenu.addClass("open");
				$dimedLayer.addClass("active");
				$tabMenuList.children().first().find(".linker").focus();
			}
		};
		p.dimedException = function(condition){
			if(condition){
				$dimedLayer.removeClass("active");
			}
		};

		p.resizeReset = function(e) {
			if (e.matches) {
				// reset desktop layout
				$tabMenuList.removeClass("open");
				$btnDropMenu.removeClass("open");
				$dimedLayer.removeClass("active");
			} else {
				// clear mobile process
			}
		};

		p.scrollAutoFocusing = function(top){
			var onIdx = 0;
			for (var i = 0; i < $linkers.length; i++) {
				var linkerTarget = $linkers.eq(i).get(0).getAttribute('href'),
					partTop = $(linkerTarget).offset().top - $this.outerHeight() - 50,
					hasPadding = ["#pdp_review", "#pdp_spec"];

				if(hasPadding.indexOf(linkerTarget) >= 0) {
					partTop = partTop - 50;
				}

				if(partTop <= top) {
					onIdx = i;
				}
			}

			$linkers.eq(onIdx).parent().addClass('active').siblings().removeClass('active');
			
		};
		p.init();

	}
	tab_sticky();
});
