$(document).ready(function() {
	if(!document.querySelector('.C0074')) return false;

	var $componentObj = $('.C0074');
	$componentObj.each(function(){
		var $slideObj = $(this).find('.slider-for');
		var $slideNavObj = $(this).find('.slider-nav');
		$slideObj.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			draggable : false,
			asNavFor: $slideNavObj,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						dots: true,
						draggable : true,
						fade: false
					}
				}
			]
		});
		$slideNavObj.slick({
			infinite: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: $slideObj,
			arrows : true,
			dots: false,
			focusOnSelect: true,
			prevArrow: carouselOptions.bigAnglePrev, // common.js variable
			nextArrow: carouselOptions.bigAngleNext // common.js variable
		});

		if( $slideNavObj.find('.item').length < 6 ) {
			$slideNavObj.find('.slick-track').addClass('reset-position');
		}
	});
});



