$(document).ready(function() {
	if(!document.querySelector('.C0078')) return false;
	
	var component55 = {
		$el : $('.C0078'),
		init : function() {
			this.$el.find('.carousel-wrap').each(function() {
				if($(this).find('.carousel-box').length>1) {
					component55.runSlick($(this));
				}
			});
		},
		runSlick: function($obj) {
			$obj.slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows : true,
				dots: true,
				//adaptiveHeight: true,
				// lazyLoad: 'ondemand',
				autoplay: true,
				autoplaySpeed: 8000,
				prevArrow: carouselOptions.bigAnglePrev, // common.js variable
				nextArrow: carouselOptions.bigAngleNext, // common.js variable
				//appendDots:$obj.find('.slick-indicator .slick-dot-wrap')
				appendDots:$obj.next().find('.slick-dot-wrap')
			});

			$obj.next().find('.slide-pause').addClass('active').on('click',function(e){
				e.preventDefault();
				if ($(this).hasClass('pause')) {
					$(this).removeClass('pause').addClass('play');
					$(this).text($(this).attr('data-title-play'));
					$obj.slick('slickPause');
				} else {
					$(this).removeClass('play').addClass('pause');
					$(this).text($(this).attr('data-title-stop'));
					$obj.slick('slickPlay');
				}
			});
			// $obj.find('.slide-pause').trigger('click');
		}
	};
	component55.init();

});